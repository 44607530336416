export const types: string[] = ["Export", "Import"];

export const categories: string[] = [
	"Total",
	"Small Domestic Appliances",
	"Major Domestic Appliances",
	"Food and Beverage Preparation",
	"Dishwashers",
	"Tumble Dryers",
	"Freezers",
	"Hoods",
	"Microwaves",
	"Built-in Ovens",
	"Hobs",
	"Free-standing Cookers",
	"Personal Care",
	"Refrigerators",
	"Vacuum Cleaners",
	"Washing machines",
];

export const units: string[] = ["Euros", "Quantity in kg"];
