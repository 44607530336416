import { FC } from "react";

import { ContentBlock } from "./ContentBlock/ContentBlock";
import { ContentBuilderProps } from "./ContentBuilder.type";

const ContentBuilder: FC<ContentBuilderProps> = ({ blocks }) => {
	return (
		<>
			{blocks.map((block) => (
				<ContentBlock key={block.id} id={block.id} block={block} />
			))}
		</>
	);
};

export { ContentBuilder };
