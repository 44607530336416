import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { TextWithImageBlockProps } from "./TextWithImageBlock.type";

const TextWithImageBlock: FC<TextWithImageBlockProps> = ({ data, id }) => {
	const { t } = useTranslation();
	const { text, align = "left", imageUrl, imageSize = "normal", type, imageAlt, title } = data;

	return (
		<div id={id} className="clear-both overflow-auto">
			<img
				{...(imageAlt && {
					alt: t(imageAlt) as string,
					title: t(imageAlt) as string,
				})}
				{...(!imageAlt && {
					role: "presentation",
				})}
				className={classNames("w-full mb-4 sm:mb-0 sm:pb-2", {
					"sm:float-left sm:pr-4 xl:pr-8": type === "image-left",
					// Type: "image-right" hide this image and show image under all paragraphs on mobile
					"hidden sm:block sm:float-right sm:pl-4 xl:pl-8": type === "image-right",
					"sm:w-1/2": imageSize === "normal",
					"sm:w-1/3": imageSize === "small",
					"sm:w-2/3": imageSize === "big",
				})}
				src={imageUrl}
			/>

			{/* Optional: text title */}
			{typeof title === "string" && title.trim().length > 0 && (
				<h3 className="leading-relaxed" dangerouslySetInnerHTML={{ __html: t(title) }} />
			)}

			{Array.isArray(text) ? (
				// Text type = array: render multiple paragraphs
				text.map((paragraph, index) => (
					<p
						key={`${id}-paragraph-${index}`}
						className={classNames("leading-relaxed", { "mb-4": index + 1 !== text.length })}
						style={{ textAlign: align }}
						dangerouslySetInnerHTML={{ __html: t(paragraph) }}
					/>
				))
			) : (
				// Text type = string: render single paragraph
				<p className="leading-relaxed" style={{ textAlign: align }} dangerouslySetInnerHTML={{ __html: t(text) }} />
			)}

			{/* Type: "image-right" put image under all paragraphs on mobile */}
			{type === "image-right" && (
				<div className="block sm:hidden">
					<img
						{...(imageAlt && {
							alt: t(imageAlt) as string,
							title: t(imageAlt) as string,
						})}
						{...(!imageAlt && {
							role: "presentation",
						})}
						className="w-full mt-4"
						src={imageUrl}
					/>
				</div>
			)}
		</div>
	);
};

export { TextWithImageBlock };
