import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

import { TERMS_AND_AGREEMENT_MODAL_COOKIE_NAME } from "./TermsAndAgreementModal.const";

const TermsAndAgreementModal: FC = () => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	// Prevent website scroll when modal is open
	useLockBodyScroll(isOpen);

	const acceptTerms = useCallback(() => {
		setIsOpen(false);
		Cookies.set(TERMS_AND_AGREEMENT_MODAL_COOKIE_NAME, "true", { expires: 365 });
	}, []);

	const handleSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			if (isChecked) {
				acceptTerms();
			}
		},
		[acceptTerms, isChecked],
	);

	// Check on mount if the user has agreed to the terms and conditions yet
	useEffect(() => {
		const isModalAccepted = Cookies.get(TERMS_AND_AGREEMENT_MODAL_COOKIE_NAME);

		if (!isModalAccepted) {
			setIsOpen(true);
		}
	}, []);

	// Return noting if the user has agreed to the terms and conditions
	if (!isOpen) {
		return null;
	}

	return (
		<div className="fixed inset-0 flex items-start md:items-center justify-center z-50 p-4 overflow-auto">
			{/* Modal backdrop overlay */}
			<div className="fixed inset-0 bg-gray-900 opacity-50" />

			{/* Modal content */}
			<section className="bg-white p-6 max-w-md mx-auto rounded shadow-lg z-10 max-h-full overflow-y-auto overflow-x-hidden">
				{/* Modal header */}
				<header className="flex justify-between items-center mb-4">
					<h2 className="h2 text-secondary">{t("termsAndAgreements.title")}</h2>
				</header>

				{/* Modal body */}
				<p className="mb-3 text-sm">{t("termsAndAgreements.body.paragraph1")}</p>
				<p className="mb-3 text-sm">{t("termsAndAgreements.body.paragraph2")}</p>
				<p className="mb-6 text-sm">{t("termsAndAgreements.body.paragraph3")}</p>

				{/* Terms and Conditions form */}
				<form onSubmit={handleSubmit}>
					<label className="flex items-start justify-start space-x-2 mb-6">
						<input type="checkbox" className="form-checkbox mt-1" required onChange={(e) => setIsChecked(e.target.checked)} />
						<span className="text-gray-800 text-sm font-semibold" dangerouslySetInnerHTML={{ __html: t("termsAndAgreements.checkbox") }} />
					</label>

					<div className="flex items-center gap-4">
						<button
							disabled={!isChecked}
							type="submit"
							className="text-white bg-primary hover:bg-primary-hover focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm text-center px-10 py-2.5 focus:outline-none disabled:bg-gray-300 transition-colors"
						>
							{t("termsAndAgreements.accept")}
						</button>
						<a href="https://www.applia-europe.eu/" className="text-sm text-primary hover:text-primary-hover">
							{t("termsAndAgreements.decline")}
						</a>
					</div>
				</form>
			</section>
		</div>
	);
};

export { TermsAndAgreementModal };
