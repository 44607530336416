import { XAxisProps, YAxisProps } from "../../../types/recharts/axis";

export const valueAccessor = (attribute: string) => {
	return ({ payload }: any): string | number => {
		if (typeof payload === "object") {
			return payload[attribute];
		}

		return 0;
	};
};

export const getTickFontSize = (props: YAxisProps | XAxisProps | undefined): number => {
	if (typeof props?.tick === "object" && typeof (props.tick as { fontSize?: number })?.fontSize === "number") {
		return (props?.tick as { fontSize: number }).fontSize;
	}

	if (typeof props?.fontSize === "number") {
		return props?.fontSize;
	}

	return 14;
};
