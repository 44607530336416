import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { StackedHorizontalBarChart } from "../../../Charts/StackedHorizontalBarChart/StackedHorizontalBarChart";
import { UiColors } from "../../../../config/ui/colors";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { WEEE_GENERATED_IN_EUROPE_CHART_DATA_KEYS } from "./WeeeGeneratedInEurope.const";
import { WeeeGeneratedInEuropeProps } from "./WeeeGeneratedInEurope.type";

const WeeeGeneratedInEurope: FC<WeeeGeneratedInEuropeProps> = ({ data }) => {
	const { t } = useTranslation();

	const chartSize = useBreakpoint();

	const [axisFontSize, setAxisFontSize] = useState<number>(14);
	const [tickCount, setTickCount] = useState<number>(13);
	const [legendaDirection, setLegendaDirection] = useState<LegendProps["direction"]>("horizontal");

	const sortedData = useMemo(() => {
		return data.sort((a, b) => +b.name - +a.name);
	}, [data]);

	const legendData = useMemo(() => {
		return WEEE_GENERATED_IN_EUROPE_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setAxisFontSize(12);
				setTickCount(5);
				setLegendaDirection("vertical");
				break;

			case "sm":
				setAxisFontSize(12);
				setTickCount(5);
				setLegendaDirection("vertical");
				break;

			case "md":
				setAxisFontSize(14);
				setTickCount(7);
				setLegendaDirection("vertical");
				break;

			case "lg":
				setAxisFontSize(14);
				setTickCount(7);
				setLegendaDirection("horizontal");
				break;

			case "xl":
				setAxisFontSize(14);
				setTickCount(7);
				setLegendaDirection("horizontal");
				break;

			default:
				setAxisFontSize(14);
				setTickCount(13);
				setLegendaDirection("horizontal");
				break;
		}
	}, [chartSize]);

	const barLabelFormatter = useCallback((value: number) => {
		// Hide labels with value of 0
		if (value === 0) {
			return "";
		}

		return `${value}`;
	}, []);

	const endOfBarLabelValueAccessor = useCallback((entry: any): string => {
		const total = entry?.coolingAndFreezing + entry?.largeHomeAppliances + entry?.smallHomeAppliances + entry?.others || 0;
		return `${new Intl.NumberFormat("nl-BE").format(total)} Mt`;
	}, []);

	return (
		<div className="flex flex-col gap-10">
			<StackedHorizontalBarChart
				data={sortedData}
				dataKeys={WEEE_GENERATED_IN_EUROPE_CHART_DATA_KEYS}
				YAxisProps={{
					dataKey: "name",
					padding: { top: 15, bottom: 0 },
					fontSize: axisFontSize,
				}}
				XAxisProps={{
					orientation: "top",
					domain: [0, 12],
					label: { value: "Mt", position: "top", fontSize: 12, fill: UiColors.black, transform: "translate(0, -3)" },
					tickCount,
					fontSize: axisFontSize,
				}}
				barLabelFormatter={barLabelFormatter}
				barLabelPosition="insideRight"
				endOfBarLabelValueAccessor={endOfBarLabelValueAccessor}
			/>
			<div className="flex justify-center">
				<div className="w-full md:w-auto">
					<Legend items={legendData} direction={legendaDirection} />
				</div>
			</div>
		</div>
	);
};

export { WeeeGeneratedInEurope };
