import { FC } from "react";

import MapDesktop from "../../../../assets/images/content/introduction/national-associations/map-national-associations-desktop.webp";
import MapMobile from "../../../../assets/images/content/introduction/national-associations/map-national-associations-mobile.webp";

const NationalAssociations: FC = () => (
	<div className="-mx-6 md:-mx-9 xl:-mx-14 -mt-8 md:-mt-12">
		<img src={MapDesktop} className="hidden md:block" role="presentation" />
		<img src={MapMobile} className="block md:hidden" role="presentation" />
	</div>
);

export { NationalAssociations };
