import { ReactComponent as Facebook } from "../../../assets/vectors/logo/facebook.svg";
import { ReactComponent as Linkedin } from "../../../assets/vectors/logo/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/vectors/logo/twitter.svg";
import { ReactComponent as Youtube } from "../../../assets/vectors/logo/youtube.svg";

export const socialLinks = [
	{ name: "Facebook", logo: Facebook, link: "https://www.facebook.com/APPLiAEurope/" },
	{ name: "Linkedin", logo: Linkedin, link: "https://www.linkedin.com/company/applia-europe/" },
	{ name: "Twitter", logo: Twitter, link: "https://twitter.com/APPLiA_Europe" },
	{ name: "Youtube", logo: Youtube, link: "https://www.youtube.com/channel/UCESWVGuceaVbTLEJN1yQKzw" },
];
