import { FC, useEffect, useMemo, useState } from "react";
import { LabelList, ResponsiveContainer, Scatter, ScatterChart, Tooltip, YAxis, ZAxis } from "recharts";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ChartSize } from "../../../types/charts/size";
import { UiBreakpoints } from "../../../config/ui/breakpoints";
import { UiColors } from "../../../config/ui/colors";
import { useTextMeasurer } from "../../../hooks/charts/useTextMeasurer";
import { useWindowSize } from "../../../hooks/useWindowSize";

import { MatrixDiagramProps } from "./MatrixDiagram.type";
import { parseDomain, renderBubble, renderBubbleLabel } from "./MatrixDiagram.helper";

import "./MatrixDiagram.css";

const MatrixDiagram: FC<MatrixDiagramProps> = ({
	data,
	titles,
	titleClassNames = "",
	Tooltip: CustomTooltip,
	range,
	yAxisTranslationLabelPrefix,
}) => {
	const { t } = useTranslation();

	const { width } = useWindowSize();

	const [chartSize, setChartSize] = useState<ChartSize>("md");
	const [chartLabelSize, setChartLabelSize] = useState<number>(14);
	const [chartAxisLabelSize, setChartAxisLabelSize] = useState<number>(14);
	const [chartYAxisLabelPosition, setChartYAxisLabelPosition] = useState<"inline" | "outside">("outside");
	const [leftMargin, setLeftMargin] = useState<number>(170);

	const { measureText } = useTextMeasurer(chartAxisLabelSize);

	const domain = useMemo(() => {
		return parseDomain(data);
	}, [data]);

	const keys = useMemo(() => {
		return Object.keys(data);
	}, [data]);

	useEffect(() => {
		if (width < UiBreakpoints.xs) {
			setChartSize("xs");
		} else if (width < UiBreakpoints.sm) {
			setChartSize("sm");
		} else if (width < UiBreakpoints.md) {
			setChartSize("md");
		} else {
			setChartSize("lg");
		}
	}, [width]);

	useEffect(() => {
		const measuredLabelWidths = keys.map((value: string) => measureText(t(`${yAxisTranslationLabelPrefix}.${value}`)));

		switch (chartSize) {
			case "xs":
				setLeftMargin(20);
				setChartYAxisLabelPosition("inline");
				setChartLabelSize(14);
				// Not showing
				setChartAxisLabelSize(14);
				break;

			case "sm":
				setLeftMargin(20);
				setChartYAxisLabelPosition("inline");
				setChartLabelSize(14);
				// Not showing
				setChartAxisLabelSize(12);
				break;

			case "md":
				setLeftMargin(20);
				setChartYAxisLabelPosition("inline");
				setChartLabelSize(14);
				// Not showing
				setChartAxisLabelSize(14);
				break;

			default:
				setLeftMargin(Math.max(...measuredLabelWidths));
				setChartYAxisLabelPosition("outside");
				setChartLabelSize(14);
				setChartAxisLabelSize(14);
				break;
		}
	}, [chartSize, measureText, t, chartAxisLabelSize, keys, yAxisTranslationLabelPrefix]);

	return (
		<div className="matrix-diagram">
			<div className="mb-8">
				<div className="flex flex-row pb-5 border-b" style={{ marginLeft: leftMargin }}>
					{titles.map((category) => {
						return (
							<div key={category} className="header-item flex-auto flex-grow-0 basis-1/6 text-center px-1 flex items-end justify-center">
								<p
									className={classNames("header-item__text font-bold leading-4", titleClassNames)}
									style={{ fontSize: chartAxisLabelSize }}
								>
									{category}
								</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className="flex flex-col w-full">
				{keys.map((key: string) => (
					<div className="flex flex-col gap-8 md:gap-4" key={key}>
						<p className="block md:hidden text-sm ml-5">
							{t(`${yAxisTranslationLabelPrefix}.${key}`)}
							<span className="text-xs text-slate-500"> (%)</span>
						</p>

						<div>
							<ResponsiveContainer width="99%" height={60}>
								<ScatterChart
									height={60}
									margin={{
										top: 10,
										right: 0,
										bottom: 10,
										left: 0,
									}}
								>
									<YAxis
										type="number"
										dataKey="index"
										name={key}
										width={leftMargin}
										tick={false}
										tickLine={false}
										axisLine={false}
										{...(chartYAxisLabelPosition === "outside" && {
											label: {
												value: t(`${yAxisTranslationLabelPrefix}.${key}`),
												position: "insideRight",
												fontSize: chartAxisLabelSize,
												transform: "translate(0, -20)",
												fill: UiColors.black,
											},
										})}
									/>
									<ZAxis type="number" dataKey="value" domain={domain} range={range} />
									<Tooltip
										cursor={false}
										wrapperStyle={{ zIndex: 100 }}
										{...(!!CustomTooltip && {
											content: (props) => <CustomTooltip {...props} material={t(`${yAxisTranslationLabelPrefix}.${key}`)} />,
										})}
										isAnimationActive={false}
									/>
									<Scatter isAnimationActive={false} data={data[key as keyof typeof data]}>
										{data[key as keyof typeof data].map(renderBubble)}
										<LabelList
											fontSize={chartLabelSize}
											position="inside"
											fill={UiColors.black}
											pointerEvents="none"
											formatter={(value: number) => (value !== 0 ? new Intl.NumberFormat("nl-BE").format(value) : "-")}
											content={renderBubbleLabel}
										/>
									</Scatter>
								</ScatterChart>
							</ResponsiveContainer>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export { MatrixDiagram };
