import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ImageGridBlockProps } from "./ImageGridBlock.type";
import { ImageGridItem } from "./ImageGridItem/ImageGridItem";

const ImageGridBlock: FC<ImageGridBlockProps> = ({ data, id }) => {
	const { t } = useTranslation();

	const { items, cols, responsiveCols, title } = data;

	const colClasses = useMemo(() => {
		const colClassesToReturn = `grid-cols-${cols}`;

		return Object.entries(responsiveCols || {})?.reduce((classnames, [screen, cols]) => {
			return classnames + ` ${screen}:grid-cols-${cols}`;
		}, colClassesToReturn);
	}, [cols, responsiveCols]);

	return (
		<div className="flex flex-col gap-4" id={id}>
			{/* Optional: title */}
			{typeof title === "string" && title.trim().length > 0 ? (
				<h3 className="leading-relaxed" dangerouslySetInnerHTML={{ __html: t(title) }} />
			) : null}

			<ul className={classNames("w-full grid gap-5", colClasses)}>
				{items.map((item) => (
					<li key={item.id} className="flex items-center justify-center">
						<ImageGridItem item={item}>
							<img
								src={item.imageUrl}
								title={item?.imageAlt ? (t(item?.imageAlt) as string) : ""}
								alt={item?.imageAlt ? (t(item?.imageAlt) as string) : ""}
								role="presentation"
							/>
							{item.text && <p>{t(item.text)}</p>}
						</ImageGridItem>
					</li>
				))}
			</ul>
		</div>
	);
};

export { ImageGridBlock };
