import { AppliaRoute, AppliaSiblingRoute } from "../../../config/routes/routes";

export const flattenRoutes = (routes: Array<AppliaRoute>) => {
	return routes.reduce((acc, route) => {
		if (route.url) {
			acc.push({
				label: route.label,
				url: route.url,
				pillarNumber: route?.pillarNumber,
				pathName: route?.pathName ? route?.label : undefined,
			});
		}

		if (route?.children) {
			acc.push(
				...route.children.map((child) => ({
					label: child.label,
					url: `${route?.url || "/" + route?.pathName}${child.url}`,
					pillarNumber: route?.pillarNumber,
					pathName: route?.pathName ? route?.label : undefined,
				})),
			);
		}

		return acc;
	}, [] as Array<AppliaSiblingRoute>);
};
