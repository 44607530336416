import { FC } from "react";
import { useTranslation } from "react-i18next";

import { TextBlockProps } from "./TextBlock.type";

const TextBlock: FC<TextBlockProps> = ({ data, id }) => {
	const { t } = useTranslation();
	const { text, align = "left", title } = data;
	return (
		<div className="flex flex-col gap-4" id={id}>
			{/* Optional: title */}
			{typeof title === "string" && title.trim().length > 0 ? (
				<h3 className="leading-relaxed" dangerouslySetInnerHTML={{ __html: t(title) }} />
			) : null}

			{/* Text type = string: render 1 paragraph */}
			{typeof text === "string" && (
				<p className="leading-relaxed" style={{ textAlign: align }} dangerouslySetInnerHTML={{ __html: t(text) }} />
			)}

			{/* Text type = array: render multiple paragraphs */}
			{Array.isArray(text) &&
				text.map((paragraph) => (
					<p
						className="leading-relaxed"
						key={`${id}-${paragraph}`}
						style={{ textAlign: align }}
						dangerouslySetInnerHTML={{ __html: t(paragraph) }}
					/>
				))}
		</div>
	);
};

export { TextBlock };
