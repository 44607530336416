import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { euColor, worldColor } from "./ExportAndImportInfoBox.const";
import { ExportAndImportInfoBoxProps } from "./ExportAndImportInfoBox.type";

const ExportAndImportInfoBox: FC<ExportAndImportInfoBoxProps> = ({ period, euroData, nonEuroData, worldData, unit }) => {
	const { t } = useTranslation();

	const euroDataByYear = useMemo(() => {
		if (!euroData || !period) {
			return;
		}
		return euroData.find((tradeObject) => tradeObject.period === `${period}`);
	}, [period, euroData]);
	const nonEuroDataByYear = useMemo(() => {
		if (!nonEuroData || !period) {
			return;
		}
		return nonEuroData.filter((tradeObject) => tradeObject.period === `${period}`);
	}, [period, nonEuroData]);
	const worldDataByYear = useMemo(() => {
		if (!worldData || !period) {
			return;
		}
		return worldData.find((tradeObject) => tradeObject.period === `${period}`);
	}, [period, worldData]);

	const totalValue = useMemo(() => {
		if (!euroDataByYear || !worldDataByYear) {
			return;
		}
		return euroDataByYear.value + worldDataByYear.value;
	}, [euroDataByYear, worldDataByYear]);

	const getPercentage = useCallback(
		(value: number) => {
			if (!totalValue) {
				return;
			}
			return Math.ceil((value / totalValue) * 10000) / 100;
		},
		[totalValue],
	);

	const formatValue = useCallback(
		(value: number) => {
			return `${new Intl.NumberFormat("nl-BE").format(Math.ceil(value / 1000000))} M${unit === "valueInEuros" ? "€" : "KG"}`;
		},
		[unit],
	);

	return (
		<div className="h3 flex flex-col w-full border-2 border-gray-400 rounded-md p-4 gap-1">
			{totalValue && (
				<div className="flex font-bold justify-between">
					<div className="justify-start">{period}</div>
					<div className="flex justify-end gap-2 xs:gap-5">
						<div>{formatValue(totalValue)}</div>
						<div>100%</div>
					</div>
				</div>
			)}

			<div className="flex font-bold justify-between text-sm sm:text-base">
				{/* EU total */}
				<div className="flex items-center gap-3 flex-grow-0">
					<span className="w-3 h-3 rounded-full" style={{ background: euColor }} />
					<div className="justify-start">
						{t("pages.pillar.pillar2.evolutionOfImportAndExportOfHomeAppliancesInEurope.blocks.infoBox.europeanUnion")}
					</div>
				</div>
				{euroDataByYear && (
					<div className="flex grow justify-end gap-2 xs:gap-5">
						<div className="text-right">{formatValue(euroDataByYear.value)}</div>
						<div className="flex basis-12 text-right">{`${getPercentage(euroDataByYear.value)}%`}</div>
					</div>
				)}
			</div>

			{/* Non euro total */}
			<div className="flex font-bold justify-between gap-5 text-sm sm:text-base">
				<div className="flex items-center justify-start gap-3 flex-grow-0">
					<span className="w-3 h-3 rounded-full" style={{ background: worldColor }} />
					<div className="flex-grow-0 flex-shrink text-sm sm:text-base">
						{t("pages.pillar.pillar2.evolutionOfImportAndExportOfHomeAppliancesInEurope.blocks.infoBox.restOfTheWorld")}
					</div>
				</div>
				{worldDataByYear && (
					<div className="flex grow justify-end gap-2 xs:gap-5">
						<div className="text-right">{formatValue(worldDataByYear.value)}</div>
						<div className="basis-12 text-right">{`${getPercentage(worldDataByYear.value)}%`}</div>
					</div>
				)}
			</div>

			{/* Non euro summary */}
			<div className="ml-6 md:ml-10 text-sm sm:text-base">
				{nonEuroDataByYear &&
					nonEuroDataByYear.map((nonEuroObject) => (
						<div key={`${nonEuroObject.regionName}-${nonEuroObject.value}-${nonEuroObject.period}`} className="flex justify-between gap-5">
							<div className="flex-grow-0">{nonEuroObject.regionName}</div>
							<div className="flex grow justify-end gap-2 xs:gap-5">
								<div>{formatValue(nonEuroObject.value)}</div>
								<div className="basis-12 text-right">{`${getPercentage(nonEuroObject.value)}%`}</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export { ExportAndImportInfoBox };
