import { UiColors } from "../../../../config/ui/colors";

export const WEEE_GENERATED_BY_EU_MEMBER_STATES_CHART_DATA_KEYS = [
	{
		name: "collected",
		color: UiColors.charts.primary,
		translation: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.chart.dataKeys.collected",
	},
	{
		name: "wasteBin",
		color: UiColors.charts.secondary,
		translation: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.chart.dataKeys.wasteBin",
	},
	{
		name: "complementary",
		color: UiColors.charts.tertiary,
		translation: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.chart.dataKeys.complementary",
	},
	{
		name: "gap",
		color: UiColors.charts.quaternary,
		translation: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.chart.dataKeys.gap",
	},
];
