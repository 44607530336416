import { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppliaRoute } from "../../../config/routes/routes";
import { CircularProgressBar } from "../../CircularProgressBar/CircularProgressBar";
import { getRouteCollapsible } from "../../../utils/router/getRouteCollapsible/getRouteCollapsible";
import { parseRouteLabel } from "../../../utils/router/parseRouteLabel/parseRouteLabel";
import { ReactComponent as ArrowUpRight } from "../../../assets/vectors/icons/arrow-up-right.svg";
import { SocialRow } from "../SocialRow/SocialRow";
import { UiColors } from "../../../config/ui/colors";
import { useContentStatus } from "../../../hooks/content/useContentStatus";

import { SidebarCollapsible } from "./SidebarCollapsible/SidebarCollapsible";
import { SidebarItem } from "./SidebarItem/SidebarItem";
import { SidebarProps } from "./Sidebar.type";
import { useSidebarScrollPositionOnPageLoad } from "./hooks/useSidebarScrollPositionOnPageLoad";

import "./Sidebar.css";

const Sidebar = ({ routes, mobile = false }: SidebarProps) => {
	const location = useLocation();
	const { t } = useTranslation();

	const { percentageCompleted } = useContentStatus(routes);
	const { itemsRef } = useSidebarScrollPositionOnPageLoad(routes, mobile);

	const [openCollapsibleLabel, setOpenCollapsibleLabel] = useState<string | undefined>(undefined);

	// Open (sub)route collapsible on when navigation changes
	useEffect(() => {
		if (mobile) {
			return;
		}

		// reset open collapsible to default when navigating to homescreen
		if (location.pathname === "/") {
			setOpenCollapsibleLabel(undefined);
		}

		// Get collapsible for current route & open it
		const currentRouteCollapsible = getRouteCollapsible(routes, location.pathname);

		if (currentRouteCollapsible?.label) {
			setOpenCollapsibleLabel(currentRouteCollapsible.label);
		}
	}, [routes, location, mobile]);

	// JS Map is used for storing sidebar link refs
	// (used for scrolling it in to view after page refresh)
	const getMap = useCallback(() => {
		if (!itemsRef.current) {
			// Initialize the Map on first usage.
			itemsRef.current = new Map();
		}

		return itemsRef.current;
	}, [itemsRef]);

	const handleCollapseRouteClick = useCallback(
		(route: AppliaRoute) => {
			if (mobile) {
				return;
			}
			setOpenCollapsibleLabel(route.label);
		},
		[mobile],
	);

	return (
		<nav className="w-full h-full" aria-label="Sidebar">
			<div className="h-full px-3 py-4 overflow-y-auto overflow-x-hidden bg-romance">
				{process.env.NODE_ENV !== "production" && (
					<div className="mt-4 space-y-2">
						<NavLink className="flex gap-3 pr-3 pl-5 flex-1 items-center justify-start" to="content-status-overview">
							<CircularProgressBar value={percentageCompleted} size={35} />
							<span>Content status overview</span>
						</NavLink>
					</div>
				)}

				{routes.map((route, index) => (
					<SidebarCollapsible
						key={route.label}
						label={parseRouteLabel(route, t(route.label) as string)}
						title={t(route?.label)}
						active={location.pathname === route?.url}
						onClick={() => handleCollapseRouteClick(route)}
						open={mobile || (!openCollapsibleLabel && index === 0) || route.label === openCollapsibleLabel}
						url={route?.url}
						ref={(node) => {
							const map = getMap();

							if (node && !mobile) {
								map.set(route?.label, node);
							} else {
								map.delete(route?.label);
							}
						}}
					>
						{Array.isArray(route?.children)
							? route.children.map(({ url = "", label }) => (
									<SidebarItem
										ref={(node) => {
											const map = getMap();

											if (node && !mobile) {
												map.set(label, node);
											} else {
												map.delete(label);
											}
										}}
										key={label}
										id={label}
										title={t(label) as string}
										to={route?.url ? route?.url + url : route?.pathName ? route?.pathName + url : url}
									>
										{t(label)}
									</SidebarItem>
							  ))
							: null}
					</SidebarCollapsible>
				))}
				<div className="mt-4 space-y-2 p-2 pt-4">
					<a className="ml-3 text-lg flex items-center gap-1" target="_blank" href="https://www.applia-europe.eu/" rel="noreferrer">
						{t("navigation.visitAppliaWebsite")}
						<ArrowUpRight width={20} height={20} color={UiColors.secondary} />
					</a>
				</div>
				<div className="block lg:hidden mt-8 border-t border-gray-200 pt-6">
					<SocialRow />
				</div>
			</div>
		</nav>
	);
};

export { Sidebar };
