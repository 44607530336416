import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/images/content/pillar-1/weee-flow-in-europe/weee-flow-in-europe-desktop.webp";
import Mobile from "../../../../assets/images/content/pillar-1/weee-flow-in-europe/weee-flow-in-europe-mobile.webp";

const WeeeFlowInEurope: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<img
				className="hidden sm:block"
				src={Desktop}
				alt={t("pages.pillar.pillar1.weeeFlowInEurope.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar1.weeeFlowInEurope.blocks.visual.alt") as string}
			/>
			<img
				className="block sm:hidden"
				src={Mobile}
				alt={t("pages.pillar.pillar1.weeeFlowInEurope.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar1.weeeFlowInEurope.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { WeeeFlowInEurope };
