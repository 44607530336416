import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Bubble } from "../../../Charts/BubbleChart/BubbleChart.type";
import { BubbleChart } from "../../../Charts/BubbleChart/BubbleChart";
import { FilterDropdown } from "../shared/filter/FilterDropdown";
import { FilterInput } from "../shared/filter/FilterDropdown.type";

import { LABEL_COLOR } from "./Top10ImportAndExportCountries.const";
import { getUnit } from "./Top10ImportAndExportCountries.helper";
import { Top10ImportAndExportCountriesProps } from "./Top10ImportAndExportCountries.type";

const Top10ImportAndExportCountries: FC<Top10ImportAndExportCountriesProps> = ({ data }) => {
	const [type, setType] = useState<"Import" | "Export">("Export");
	const [category, setCategory] = useState<string>("Total");
	const [unit, setUnit] = useState<"valueInEuros" | "quantityInKg">("valueInEuros");

	const { t } = useTranslation();

	// Left dataset
	const leftBubblechartDataset = useMemo(() => {
		if (!data || !type || !category || !unit) {
			return;
		}
		const rawData2015 = data.filter((tradeObject) => tradeObject.period === "2015");
		if (!rawData2015 || rawData2015.length < 0) {
			return;
		}
		const filteredData = rawData2015.filter((tradeObject) => tradeObject.flowName === type && tradeObject.familyName === category);
		return filteredData
			.map((tradeObject) => {
				return { label: tradeObject.countryReporter.toString(), value: tradeObject[unit] };
			})
			.sort((a, b) => b.value - a.value)
			.slice(0, 10) as Bubble[];
	}, [category, data, type, unit]);

	// Right dataset
	const rightBubblechartDataset = useMemo(() => {
		if (!data) {
			return;
		}
		const rawData2021 = data.filter((tradeObject) => tradeObject.period === "2021");
		if (!rawData2021 || rawData2021.length < 0) {
			return;
		}
		const filteredData = rawData2021.filter((tradeObject) => tradeObject.flowName === type && tradeObject.familyName === category);

		return filteredData
			.map((tradeObject) => {
				return { label: tradeObject.countryReporter.toString(), value: tradeObject[unit] };
			})
			.sort((a, b) => b.value - a.value)
			.slice(0, 10) as Bubble[];
	}, [category, data, type, unit]);

	const maxValue = useMemo(() => {
		if (!leftBubblechartDataset || !rightBubblechartDataset) {
			return;
		}
		const leftMax = Math.max(...leftBubblechartDataset.map((bubble) => bubble.value));
		const rightMax = Math.max(...rightBubblechartDataset.map((bubble) => bubble.value));
		return leftMax >= rightMax ? leftMax : rightMax;
	}, [leftBubblechartDataset, rightBubblechartDataset]);

	const numberMillionFormat = useCallback(
		(value: number) => {
			const milionRoundUp = Math.ceil(value / 1000000);
			const unitChar = unit === "valueInEuros" ? "€" : "KG";
			return `${milionRoundUp.toLocaleString("nl-BE")} M${unitChar}`;
		},
		[unit],
	);

	const handleDropdownChange = useCallback((value: FilterInput) => {
		const { type: selectedType, category: selectedCategory, unit: selectedUnit } = value;
		setType(selectedType as "Import" | "Export");
		setCategory(selectedCategory);
		setUnit(`${getUnit(selectedUnit)}`);
	}, []);

	return (
		<div>
			<div className="mb-10">
				<FilterDropdown onChange={handleDropdownChange} />
				<h3 className="flex gap-2 my-10">
					<span>{t("pages.pillar.pillar2.Top10ImportAndExportCountries.blocks.chartTitle.top10")}</span>
					<span className="uppercase text-secondary font-bold">
						{` ${type} ${
							type === "Import"
								? t("pages.pillar.pillar2.Top10ImportAndExportCountries.blocks.chartTitle.origins")
								: t("pages.pillar.pillar2.Top10ImportAndExportCountries.blocks.chartTitle.destinations")
						}`}
					</span>
				</h3>
			</div>
			<div className="flex sm:flex-row flex-col gap-40 lg:ml-5 ml-10">
				<div className="sm:w-1/3 w-3/4 sm:ml-0 ml-5">
					{leftBubblechartDataset && (
						<BubbleChart
							data={leftBubblechartDataset}
							max={maxValue}
							height={750}
							margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
							YAxisProps={{ strokeWidth: "2px", tickLine: { stroke: "#b2b2b2" }, dx: -5 }}
							XAxisProps={{
								label: {
									value: "2015",
									position: "insideRight",
									transform: "translate(25, -20)",
									fontWeight: 800,
									fill: LABEL_COLOR,
								},
								orientation: "top",
							}}
							labelFormatter={numberMillionFormat}
						/>
					)}
				</div>
				<div className="sm:w-1/3 w-3/4 sm:ml-0 ml-5">
					{rightBubblechartDataset && (
						<BubbleChart
							data={rightBubblechartDataset}
							max={maxValue}
							height={750}
							margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
							YAxisProps={{ strokeWidth: "2px", tickLine: { stroke: "#b2b2b2" }, dx: -5 }}
							XAxisProps={{
								label: {
									value: "2021",
									position: "insideRight",
									transform: "translate(25, -20)",
									fontWeight: 800,
									fill: LABEL_COLOR,
								},
								orientation: "top",
							}}
							labelFormatter={numberMillionFormat}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export { Top10ImportAndExportCountries };
