import { AppliaChildRoute } from "./../../../config/routes/routes";
import { AppliaRoute } from "../../../config/routes/routes";

export const getRouteInfo = (routes: AppliaRoute[], currentRoute: string): AppliaRoute | AppliaChildRoute | undefined => {
	let currentRouteInfo;

	routes.forEach((route) => {
		if (route?.url && route?.url === currentRoute) {
			currentRouteInfo = route;
			return;
		}

		if (!Array.isArray(route?.children)) {
			return;
		}

		route?.children.forEach((child) => {
			if (route?.pathName && `/${route?.pathName}${child?.url}` === currentRoute) {
				currentRouteInfo = child;
				return;
			}

			if ((route?.url && `${route?.url}${child?.url}` === currentRoute) || child?.url === currentRoute) {
				currentRouteInfo = child;
				return;
			}
		});
	});

	return currentRouteInfo;
};
