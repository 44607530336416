export const REPAIR_OF_HOME_APPLIANCES_CHART_DATA_KEYS = [
	{
		name: "spareParts",
		translation: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.chart.dataKeys.spareParts",
		color: "#1d3969",
	},
	{
		name: "labour",
		translation: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.chart.dataKeys.labour",
		color: "#0059a0",
	},
	{
		name: "transport",
		translation: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.chart.dataKeys.transport",
		color: "#1d80c3",
	},
	{
		name: "other",
		translation: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.chart.dataKeys.other",
		color: "#48a8de",
	},
];
