import { FC } from "react";
import { useTranslation } from "react-i18next";

import EnergyReductionVisual from "../../../../assets/images/content/pillar-1/material-use-during-production-process/Material-use-during-pp-3.webp";
import WasteReductionVisual from "../../../../assets/images/content/pillar-1/material-use-during-production-process/Material-use-during-pp-1.webp";
import WaterReductionVisual from "../../../../assets/images/content/pillar-1/material-use-during-production-process/Material-use-during-pp-2.webp";

const MaterialUseDuringTheProductionProcess: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex justify-start">
			<div className="w-full 2xl:w-3/4 grid grid-cols-1 sm:grid-cols-3 gap-10 sm:gap-6 2xl:gap-10">
				<div className="flex flex-col gap-4">
					<img
						className="w-full h-auto"
						src={WasteReductionVisual}
						alt={t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.wasteReduction.alt") as string}
						title={t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.wasteReduction.alt") as string}
					/>
					<div className="flex flex-col gap-2">
						<p className="text-base" aria-hidden>
							{t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.wasteReduction.title")}
						</p>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<img
						className="w-full h-auto"
						src={WaterReductionVisual}
						alt={t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.waterReduction.alt") as string}
						title={t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.waterReduction.alt") as string}
					/>
					<div className="flex flex-col gap-2">
						<p className="text-base" aria-hidden>
							{t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.waterReduction.title")}
						</p>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<img
						className="w-full h-auto"
						src={EnergyReductionVisual}
						alt={t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.energyReduction.alt") as string}
						title={t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.energyReduction.alt") as string}
					/>
					<div className="flex flex-col gap-2">
						<p className="text-base" aria-hidden>
							{t("pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual.energyReduction.title")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export { MaterialUseDuringTheProductionProcess };
