import { useCallback, useMemo } from "react";

export const useTextMeasurer = (fontSize?: number) => {
	const ctx = useMemo<CanvasRenderingContext2D | null>(() => {
		const element = document.createElement("canvas").getContext("2d");

		if (element) {
			element.font = `${fontSize || 14}px "museo-sans-rounded"`;
		}

		return element;
	}, [fontSize]);

	const measureText = useCallback(
		(text: string) => {
			return ctx?.measureText(text).width || 0;
		},
		[ctx],
	);

	return { measureText };
};
