import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BarChartData } from "../../../../types/recharts/barChart";
import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { StackedHorizontalBarChart } from "../../../Charts/StackedHorizontalBarChart/StackedHorizontalBarChart";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import RepairVisual1 from "../../../../assets/images/content/pillar-1/repair-of-home-appliances/repair-1.webp";
import RepairVisual2 from "../../../../assets/images/content/pillar-1/repair-of-home-appliances/repair-2.webp";
import RepairVisual3 from "../../../../assets/images/content/pillar-1/repair-of-home-appliances/repair-3.webp";
import RepairVisual4 from "../../../../assets/images/content/pillar-1/repair-of-home-appliances/repair-4.webp";

import { REPAIR_OF_HOME_APPLIANCES_CHART_DATA_KEYS } from "./RepairOfHomeAppliances.const";
import { RepairOfHomeAppliancesProps } from "./RepairOfHomeAppliances.type";

const RepairOfHomeAppliances: FC<RepairOfHomeAppliancesProps> = ({ data }) => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	const [legendDirection, setLegendDirection] = useState<LegendProps["direction"]>("horizontal");

	const translatedBarChartData = useMemo<BarChartData>(() => {
		return data.map((dataEntry) => ({
			...dataEntry,
			name: t(`pages.pillar.pillar1.repairOfHomeAppliances.blocks.chart.yAxisLabels.${dataEntry.name}`),
		}));
	}, [data, t]);

	const legendData = useMemo(() => {
		return REPAIR_OF_HOME_APPLIANCES_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
			case "sm":
				setLegendDirection("vertical");
				break;

			case "md":
			case "lg":
			case "xl":
			default:
				setLegendDirection("horizontal");
				break;
		}
	}, [chartSize]);

	const barLabelFormatter = useCallback((value: number) => {
		return `${value}%`;
	}, []);

	return (
		<div className="flex flex-col gap-6">
			<div className="w-full self-start 2xl:w-3/4 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-10 md:gap-6 2xl:gap-10">
				<div className="flex flex-col gap-4">
					<img className="w-full h-auto" src={RepairVisual1} role="presentation" />
					<div className="flex flex-col">
						<p className="text-2xl font-bold text-primary">{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.units.value")}</p>
						<p className="text-sm">{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.units.label")}</p>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<img className="w-full h-auto" src={RepairVisual2} role="presentation" />
					<div className="flex flex-col">
						<p className="text-2xl font-bold text-primary">
							{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.industryTurnover.value")}
						</p>
						<p className="text-sm">{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.industryTurnover.label")}</p>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<img className="w-full h-auto" src={RepairVisual3} role="presentation" />
					<div className="flex flex-col">
						<p className="text-2xl font-bold text-primary">
							{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.repairRequestRate.value")}
						</p>
						<p className="text-sm">{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.repairRequestRate.label")}</p>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<img className="w-full h-auto" src={RepairVisual4} role="presentation" />
					<div className="flex flex-col">
						<p className="text-2xl font-bold text-primary">
							{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.partners.value")}
						</p>
						<p className="text-sm">{t("pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual.partners.label")}</p>
					</div>
				</div>
			</div>

			<div className="self-stretch md:self-center mt-4">
				<Legend items={legendData} direction={legendDirection} />
			</div>

			<StackedHorizontalBarChart
				data={translatedBarChartData}
				dataKeys={REPAIR_OF_HOME_APPLIANCES_CHART_DATA_KEYS}
				YAxisProps={{
					dataKey: "name",
					padding: { top: 10, bottom: 10 },
					fontSize: 12,
				}}
				XAxisProps={{ unit: "%", tickCount: 5, tick: { fontSize: 12 } }}
				barLabelFormatter={barLabelFormatter}
				tooltipUnit="%"
			/>
		</div>
	);
};

export { RepairOfHomeAppliances };
