import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppliaChildRoute, AppliaRoute, AppliaSiblingRoute, routes } from "../../config/routes/routes";
import { getRouteCollapsible } from "./../../utils/router/getRouteCollapsible/getRouteCollapsible";
import { getRouteInfo } from "../../utils/router/getRouteInfo/getRouteInfo";
import { getRouteSiblings } from "../../utils/router/getRouteSiblings/getRouteSiblings";
import { parseRouteTitle } from "../../utils/router/parseRouteTitle/parseRouteTitle";

export const useRouteInfo = () => {
	const { t } = useTranslation();
	const location = useLocation();

	const [description, setDescription] = useState("");
	const [next, setNext] = useState<AppliaSiblingRoute>();
	const [previous, setPrevious] = useState<AppliaSiblingRoute>();
	const [routeInfo, setRouteInfo] = useState<AppliaRoute | AppliaChildRoute>();
	const [routeParent, setRouteParent] = useState<AppliaRoute>();
	const [title, setTitle] = useState("");

	useEffect(() => {
		if (!location?.pathname) {
			setTitle("");
			setDescription("");
			setRouteInfo(undefined);
			setPrevious(undefined);
			setNext(undefined);

			return;
		}

		const info = getRouteInfo(routes, location.pathname);
		const parentNode = getRouteCollapsible(routes, location.pathname);
		const title = parseRouteTitle(parentNode, info, t);

		const { next, previous } = getRouteSiblings(location.pathname, routes);

		setNext(next);
		setPrevious(previous);

		setTitle(title);
		setDescription(info?.description || "");
		setRouteInfo(info);
		setRouteParent(parentNode);
	}, [location, t]);

	return {
		description,
		next,
		previous,
		routeData: routeInfo,
		routeParent,
		title,
	};
};
