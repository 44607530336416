import { SankeyDataNode } from "../../../Charts/SankeyDiagram/SankeyDiagram.type";

export const nodesData: SankeyDataNode[] = [
	{
		label: "EU27",
		nodeColor: "#0047ba",
	},
	{
		label: "Rest of Europe",
		nodeColor: "#0099a8",
	},
	{
		label: "Asia",
		nodeColor: "#999999",
	},
	{
		label: "Americas",
		nodeColor: "#999999",
	},
	{
		label: "Africa",
		nodeColor: "#999999",
	},
	{
		label: "Oceania",
		nodeColor: "#999999",
	},
	{
		label: "Total",
		nodeColor: "#0047ba",
	},
];

export const linkConnectionColors = { euColor: "#b3c8ea", restOfEuColor: "#b3e0e5", defaultColor: "#e0e0e0" };
export const unitValue: { euro: "valueInEuros"; kg: "quantityInKg" } = { euro: "valueInEuros", kg: "quantityInKg" };
