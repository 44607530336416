import { FC } from "react";

import { Alert } from "../Alert/Alert";

import { ContentStatusProps } from "./ContentStatus.type";
import { getAlertBody, getAlertTitle, getAlertType } from "./ContentStatus.helper";

const ContentStatus: FC<ContentStatusProps> = ({ status }) => {
	const alertType = getAlertType(status);
	const alertTitle = getAlertTitle(status);
	const alertBody = getAlertBody(status);

	return (
		<>
			{process.env.NODE_ENV !== "production" ? (
				<div className="my-8 md:my-12">
					<Alert type={alertType} title={alertTitle} message={alertBody} />
				</div>
			) : null}
		</>
	);
};

export { ContentStatus };
