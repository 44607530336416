import { FC } from "react";
import classNames from "classnames";

import { ContainerProps } from "./Container.type";

const Container: FC<ContainerProps> = ({
	children,
	containerClassName = "",
	contentClassName = "",
	contentPaddingClasses = "px-6 py-8 md:px-9 md:py-9 xl:p-14",
	src,
}) => {
	return (
		<div className={classNames("w-full relative overflow-hidden", containerClassName)}>
			{src ? (
				<>
					<div className="w-full h-full absolute left-0 top-0 bg-cover bg-center z-0" style={{ backgroundImage: `url(${src})` }} />
					<div className="w-full h-full absolute left-0 top-0 z-[1] bg-black opacity-10" />
					<div
						className="w-1/2 h-full absolute left-0 top-0 z-[2]"
						style={{
							background:
								"linear-gradient(100.6deg, rgba(57, 74, 108, 0.378) 14.5%, rgba(49, 80, 117, 0.297) 35.05%, rgba(21, 76, 141, 0.045) 56.16%)",
							filter: "blur(25px)",
						}}
					/>
					<div
						className="w-full h-full absolute left-0 top-0 z-[3]"
						style={{
							background:
								"linear-gradient(90.38deg, rgba(35, 53, 99, 0.5) 5.56%, rgba(41, 81, 129, 0.5) 28.38%, rgba(21, 76, 141, 0.125) 59.47%)",
						}}
					/>
				</>
			) : null}
			<div
				className={classNames(
					"w-full h-full relative z-[3] grow shrink-0 flex flex-col items-start justify-start",
					contentPaddingClasses,
					contentClassName,
				)}
			>
				{children}
			</div>
		</div>
	);
};

export { Container };
