import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ImageBlockProps } from "./ImageBlock.type";

const ImageBlock: FC<ImageBlockProps> = ({ id, data }) => {
	const { t } = useTranslation();

	const { imageSize, imageAlt, imageUrl, title, imageAlignment = "center" } = data;

	const className = useMemo(() => {
		switch (imageSize) {
			case "small":
				return "basis-full sm:basis-1/3 grow-0 shrink-0";
			case "normal":
				return "basis-full sm:basis-1/2 grow-0 shrink-0";
			case "big":
				return "basis-full sm:basis-2/3 grow-0 shrink-0";
			case "fluid":
				return "w-full";
			default:
				break;
		}
	}, [imageSize]);

	return (
		<div className="flex flex-col gap-4" id={id}>
			{/* Optional: title */}
			{typeof title === "string" && title.trim().length > 0 ? (
				<h3 className="leading-relaxed" dangerouslySetInnerHTML={{ __html: t(title) }} />
			) : null}
			<div
				className={classNames("flex", {
					"justify-start": imageSize !== "fluid" && imageAlignment === "left",
					"justify-center": imageSize === "fluid" || imageAlignment === "center",
					"justify-end": imageSize !== "fluid" && imageAlignment === "right",
				})}
			>
				<div className={className}>
					<img
						src={imageUrl}
						{...(imageAlt && {
							alt: t(imageAlt) as string,
							title: t(imageAlt) as string,
						})}
						{...(!imageAlt && {
							role: "presentation",
						})}
						className="w-full h-auto"
					/>
				</div>
			</div>
		</div>
	);
};

export { ImageBlock };
