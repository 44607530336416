export const years: number[] = [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022];

export const categories: string[] = [
	"Total",
	"Small Domestic Appliances",
	"Major Domestic Appliances",
	"Food and Beverage Preparation",
	"Dishwashers",
	"Tumble Dryers",
	"Freezers",
	"Hoods",
	"Microwaves",
	"Built-in Ovens",
	"Hobs",
	"Free-standing Cookers",
	"Personal Care",
	"Refrigerators",
	"Vacuum Cleaners",
	"Washing machines",
];

export const units: string[] = ["Euros", "Quantity in kg"];
