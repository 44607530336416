import * as d3 from "d3";
import * as d3Sankey from "d3-sankey";

import { parsePercentage } from "../../../utils/parsePercentage";

import { SankeyDataLink, SankeyDataNode } from "./SankeyDiagram.type";

export const sankeyLoader = d3Sankey
	.sankey<SankeyDataNode, SankeyDataLink>()
	.nodeId((node) => node.label)
	.nodeWidth(20)
	.nodePadding(30);

export const setupNodeBlocks = (svg: SVGSVGElement, graph: d3Sankey.SankeyGraph<SankeyDataNode, SankeyDataLink>) => {
	d3.select(svg)

		.select("#content")
		.selectAll(".node")
		.data(graph.nodes)
		.enter()
		.append("g")
		.attr("class", "node")
		.attr("transform", function (d) {
			return "translate(" + d.x0 + "," + d.y0 + ")";
		})
		.append("rect")
		.attr("height", function (d) {
			return `${d.y0 !== undefined && d.y1 !== undefined && d.y1 - d.y0}`;
		})
		.attr("width", 20)
		.style("fill", function (d) {
			return d.nodeColor;
		})
		.style("stroke", function (d) {
			return d.nodeColor;
		});
};

export const setupLinkPaths = (svg: SVGSVGElement, graph: d3Sankey.SankeyGraph<SankeyDataNode, SankeyDataLink>) => {
	d3.select(svg)
		.select("#content")
		.selectAll("path")
		.data(graph.links)
		.join("path")
		.attr("d", d3Sankey.sankeyLinkHorizontal<SankeyDataNode, SankeyDataLink>())
		.attr("stroke-width", function (d) {
			return Math.max(1, d.width || 0);
		})
		.attr("fill-opacity", 0)
		.attr("stroke", (d) => (d.connectionColor ? d.connectionColor : "#FFF"))
		.attr("name", (d) => d.source);
};

const searchLinkBySource = (id: string, links: any): SankeyDataLink | undefined => {
	const result = links.find((link: any) => link.source.label === id);
	if (!result) {
		return;
	}
	return result;
};

const searchLinkByTarget = (id: string, links: any): SankeyDataLink | undefined => {
	const result = links.find((link: any) => link.target.label === id);
	if (!result) {
		return;
	}
	return result;
};

export const getLabelPadding = (ruler: (text: string) => number, nodes: SankeyDataNode[], links: SankeyDataLink[]) => {
	const measuredLongLabelList = nodes.map((sankeyNode) => {
		return ruler(sankeyNode.label);
	});
	const longLabelPadding = Math.max(...measuredLongLabelList);

	const measuredValueList = links.map((sankeyLink) => {
		return ruler(`${sankeyLink.value}`);
	});
	const valueLabelPadding = Math.max(...measuredValueList);

	const percentLabelPadding = ruler("100,00%");

	return { longLabelPadding, valueLabelPadding, percentLabelPadding };
};

export const setupLabels = (
	svg: SVGSVGElement,
	graph: d3Sankey.SankeyGraph<SankeyDataNode, SankeyDataLink>,
	position: "left" | "right",
	fontSize: number,
	padding: { longLabelPadding: number; valueLabelPadding: number; percentLabelPadding: number },
	tickFormatter: (tick: string) => string,
) => {
	const group = d3.select(svg).select("#labels").selectAll("g").data(graph.nodes).join("g");

	const totalValue = graph.links.reduce((total, link) => (total = total + link.value), 0);

	let gapBetween = 10;

	group
		.append("text")
		.attr("x", (d) => (d.x0 ? d.x0 : 0))
		.attr("y", (d) => (d.y0 ? d.y0 : 0))
		.text((d) => {
			const link = position === "right" ? searchLinkBySource(d.label, graph.links) : searchLinkByTarget(d.label, graph.links);
			return link ? parsePercentage(totalValue, link.value) : "";
		})
		.attr("font-size", fontSize)
		.attr("transform", function () {
			const { width, height } = this.getBBox();
			return position === "right" ? `translate(-${width + gapBetween}, ${height})` : `translate(${20 + gapBetween}, ${height})`;
		});
	gapBetween = gapBetween + padding.percentLabelPadding * 1.3;

	group
		.append("text")
		.attr("x", (d) => (d.x0 ? d.x0 : 0))
		.attr("y", (d) => (d.y0 ? d.y0 : 0))
		.text((d) => {
			const link = position === "right" ? searchLinkBySource(d.label, graph.links) : searchLinkByTarget(d.label, graph.links);
			return link ? tickFormatter(`${link.value}`) : "";
		})
		.attr("font-size", fontSize)
		.attr("transform", function () {
			const { width, height } = this.getBBox();
			return position === "right" ? `translate(-${width + gapBetween}, ${height})` : `translate(${gapBetween}, ${height})`;
		});
	gapBetween = gapBetween + padding.valueLabelPadding * 1.2;

	group
		.append("text")
		.attr("fill", (d) => d.nodeColor)
		.attr("x", (d) => (d.x0 ? d.x0 : 0))
		.attr("y", (d) => (d.y0 ? d.y0 : 0))
		.text((d) => (d.label === "Total" ? "" : d.label))
		.attr("font-size", fontSize)
		.attr("transform", function () {
			const { width, height } = this.getBBox();
			return position === "right" ? `translate(-${width + gapBetween}, ${height})` : `translate(${gapBetween}, ${height})`;
		});
	gapBetween = gapBetween + padding.longLabelPadding;
};
