import { EUTradeObject } from "../../../Charts/shared/chart.type";
import { SankeyDataLink } from "../../../Charts/SankeyDiagram/SankeyDiagram.type";

import { linkConnectionColors, unitValue } from "./ImportAndExportOfHomeAppliancesInEuropeByContinent.const";

export const parseLinks = (
	originalList: EUTradeObject[],
	position: "left" | "right",
	groupByValue: "valueInEuros" | "quantityInKg",
): SankeyDataLink[] => {
	const [startPosition, endPosition] = position === "right" ? ["source", "target"] : ["target", "source"];

	const groupedList = originalList.map((tradeObject) => {
		const { regionName } = tradeObject;
		let connectionColor;
		switch (regionName) {
			case "EU27":
				connectionColor = linkConnectionColors.euColor;
				break;
			case "Rest of Europe":
				connectionColor = linkConnectionColors.restOfEuColor;
				break;
			default:
				connectionColor = linkConnectionColors.defaultColor;
				break;
		}
		return {
			[startPosition]: regionName,
			[endPosition]: "Total",
			value: tradeObject[groupByValue],
			connectionColor: connectionColor,
		};
	});

	return [...groupedList] as SankeyDataLink[];
};

export const getUnit = (unit: string) => {
	switch (unit) {
		case "Quantity in kg":
			return unitValue.kg;
		default:
		case "Euros":
			return unitValue.euro;
	}
};
export const getTotalValue = (links: SankeyDataLink[]): number => {
	return links.reduce((acc, current) => acc + current.value, 0);
};
