import { useEffect, useState } from "react";

import { AppliaRoute } from "../../config/routes/routes";

export const useContentStatus = (routes: AppliaRoute[] = []) => {
	const [needsApproval, setNeedsApproval] = useState(0);
	const [percentageCompleted, setPercentageCompleted] = useState(0);
	const [rejected, setRejected] = useState(0);
	const [todo, setNonVerified] = useState(0);
	const [total, setTotal] = useState(0);
	const [verified, setVerified] = useState(0);

	useEffect(() => {
		const calculations = routes.reduce(
			(previousValue, currentValue) => {
				let todo = previousValue.todo;
				let total = previousValue.total;
				let verified = previousValue.verified;
				let readyForApproval = previousValue.readyForApproval;
				let rejected = previousValue.rejected;
				let wontDo = previousValue.wontDo;

				if (currentValue.children && Array.isArray(currentValue.children)) {
					currentValue.children.forEach((child) => {
						child.status === "approved" && verified++;
						child.status === "readyForApproval" && readyForApproval++;
						child.status === "rejected" && rejected++;
						child.status === "todo" && todo++;
						child.status === "wontDo" && wontDo++;

						total++;
					});
				}

				if (currentValue.url) {
					currentValue.status === "approved" && verified++;
					currentValue.status === "readyForApproval" && readyForApproval++;
					currentValue.status === "rejected" && rejected++;
					currentValue.status === "todo" && todo++;
					currentValue.status === "wontDo" && wontDo++;
					total++;
				}

				return {
					readyForApproval,
					rejected,
					todo,
					total,
					verified,
					wontDo,
				};
			},
			{
				readyForApproval: 0,
				rejected: 0,
				todo: 0,
				total: 0,
				verified: 0,
				wontDo: 0,
			},
		);

		setVerified(calculations.verified);
		setNonVerified(calculations.todo);
		setTotal(calculations.total - calculations.wontDo);
		setRejected(calculations.rejected);
		setNeedsApproval(calculations.readyForApproval);
		setPercentageCompleted(Math.round(100 * (calculations.verified / (calculations.total - calculations.wontDo))));
	}, [routes]);

	return {
		needsApproval,
		percentageCompleted,
		rejected,
		todo,
		total,
		verified,
	};
};
