import Logo1 from "../../../../images/content/introduction/direct-members/logo1.jpg";
import Logo2 from "../../../../images/content/introduction/direct-members/logo2.jpg";
import Logo3 from "../../../../images/content/introduction/direct-members/logo3.jpg";
import Logo4 from "../../../../images/content/introduction/direct-members/logo4.jpg";
import Logo5 from "../../../../images/content/introduction/direct-members/logo5.jpg";
import Logo6 from "../../../../images/content/introduction/direct-members/logo6.jpg";
import Logo7 from "../../../../images/content/introduction/direct-members/logo7.jpg";
import Logo8 from "../../../../images/content/introduction/direct-members/logo8.jpg";
import Logo9 from "../../../../images/content/introduction/direct-members/logo9.jpg";
import Logo10 from "../../../../images/content/introduction/direct-members/logo10.jpg";
import Logo11 from "../../../../images/content/introduction/direct-members/logo11.jpg";
import Logo12 from "../../../../images/content/introduction/direct-members/logo12.jpg";
import Logo13 from "../../../../images/content/introduction/direct-members/logo13.jpg";
import Logo14 from "../../../../images/content/introduction/direct-members/logo14.jpg";
import Logo15 from "../../../../images/content/introduction/direct-members/logo15.jpg";
import Logo16 from "../../../../images/content/introduction/direct-members/logo16.jpg";
import Logo17 from "../../../../images/content/introduction/direct-members/logo17.jpg";
import Logo18 from "../../../../images/content/introduction/direct-members/logo18.jpg";
import Logo19 from "../../../../images/content/introduction/direct-members/logo19.jpg";
import Logo20 from "../../../../images/content/introduction/direct-members/logo20.jpg";
import Logo21 from "../../../../images/content/introduction/direct-members/logo21.jpg";
import Logo22 from "../../../../images/content/introduction/direct-members/logo22.jpg";
import Logo23 from "../../../../images/content/introduction/direct-members/logo23.jpg";
import Logo24 from "../../../../images/content/introduction/direct-members/logo24.jpg";
import Logo25 from "../../../../images/content/introduction/direct-members/logo25.jpg";

export const directMembers = [
	{
		id: "applia-member-arcelik",
		url: "http://www.arcelik.com.tr/",
		imageUrl: Logo1,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.arcelik.imageAlt",
	},
	{
		id: "applia-member-ariston-group",
		url: "https://www.aristongroup.com/en/",
		imageUrl: Logo2,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.ariston.imageAlt",
	},
	{
		id: "applia-member-bsh",
		url: "http://www.bsh-group.com/",
		imageUrl: Logo3,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.bsh.imageAlt",
	},
	{
		id: "applia-member-daikin",
		url: "http://www.daikineurope.com/",
		imageUrl: Logo4,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.daikin.imageAlt",
	},
	{
		id: "applia-member-délonghi",
		url: "http://www.delonghi.com/",
		imageUrl: Logo5,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.délonghi.imageAlt",
	},
	{
		id: "applia-member-dyson",
		url: "http://www.dyson.co.uk/",
		imageUrl: Logo6,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.dyson.imageAlt",
	},
	{
		id: "applia-member-electrolux",
		url: "http://www.electrolux.com/",
		imageUrl: Logo7,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.electrolux.imageAlt",
	},
	{
		id: "applia-member-gorenje",
		url: "http://www.gorenje.si/",
		imageUrl: Logo8,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.gorenje.imageAlt",
	},
	{
		id: "applia-member-groupe-atlantic",
		url: "https://www.groupe-atlantic.fr/en/",
		imageUrl: Logo9,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.atlantic.imageAlt",
	},
	{
		id: "applia-member-haier",
		url: "https://corporate.haier-europe.com/en/",
		imageUrl: Logo10,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.haier.imageAlt",
	},
	{
		id: "applia-member-iRobot",
		url: "https://www.irobot.com/",
		imageUrl: Logo11,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.iRobot.imageAlt",
	},
	{
		id: "applia-member-LG",
		url: "http://www.lg.com/",
		imageUrl: Logo12,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.LG.imageAlt",
	},
	{
		id: "applia-member-liebherr-hausgeräte",
		url: "http://www.liebherr.com",
		imageUrl: Logo13,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.liebherr-hausgeräte.imageAlt",
	},
	{
		id: "applia-member-midea-europe-gmb-h",
		url: "https://www.midea.com/de/",
		imageUrl: Logo14,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.midea-europe-gmb-h.imageAlt",
	},
	{
		id: "applia-member-miele",
		url: "http://www.miele.de",
		imageUrl: Logo15,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.miele.imageAlt",
	},
	{
		id: "applia-member-panasonic-europe-ltd",
		url: "http://www.panasonic.eu",
		imageUrl: Logo16,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.panasonic-europe-ltd.imageAlt",
	},
	{
		id: "applia-member-philips-consumer-lifestyle-b-v",
		url: "http://www.philips.com",
		imageUrl: Logo17,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.philips-consumer-lifestyle-b-v.imageAlt",
	},
	{
		id: "applia-member-procter-and-gamble-international-operations-sa",
		url: "https://fr-be.pg.com/",
		imageUrl: Logo18,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.procter-and-gamble-international-operations-sa.imageAlt",
	},
	{
		id: "applia-member-samsung-electronics",
		url: "http://www.samsung.com",
		imageUrl: Logo19,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.samsung-electronics.imageAlt",
	},
	{
		id: "applia-member-groupe-seb",
		url: "http://www.groupeseb.com",
		imageUrl: Logo20,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.groupe-seb.imageAlt",
	},
	{
		id: "applia-member-smeg",
		url: "http://www.smeg.com",
		imageUrl: Logo21,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.smeg.imageAlt",
	},
	{
		id: "applia-member-vestel",
		url: "http://www.vestel.com",
		imageUrl: Logo22,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.vestel.imageAlt",
	},
	{
		id: "applia-member-versuni",
		url: "https://www.versuni.com/",
		imageUrl: Logo23,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.versuni.imageAlt",
	},
	{
		id: "applia-member-vorwerk-international-strecker-and-co",
		url: "http://www.corporate.vorwerk.com",
		imageUrl: Logo24,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.vorwerk-international-strecker-and-co.imageAlt",
	},
	{
		id: "applia-member-whirlpool-emea",
		url: "http://www.whirlpoolcorp.com",
		imageUrl: Logo25,
		imageAlt: "pages.introduction.appliaDirectMembers.blocks.grid.whirlpool-emea.imageAlt",
	},
];
