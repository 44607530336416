import { FC } from "react";

import Desktop from "../../../../assets/images/content/pillar-2/units-traded-in-europe/units-traded-in-europe-seperate.jpg";
import Mobile from "../../../../assets/images/content/pillar-2/units-traded-in-europe/units-traded-in-europe-seperate-mobile.jpg";

const UnitsTradedInEurope: FC = () => {
	return (
		<div className="flex flex-col items-center justify-center">
			<img className="hidden md:block w-full" src={Desktop} role="presentation" />
			<img className="block md:hidden" src={Mobile} role="presentation" />
		</div>
	);
};

export { UnitsTradedInEurope };
