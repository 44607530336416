import { UiColors } from "../../../../config/ui/colors";

export const MATERIALS_RECOVERED_FROM_WASTE_CHART_DATA_KEYS = [
	{
		name: "aluminium",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.aluminium",
		color: "#1a1741",
	},
	{
		name: "concrete",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.concrete",
		color: "#183792",
	},
	{
		name: "copper",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.copper",
		color: "#316bc8",
	},
	{
		name: "glass",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.glass",
		color: "#53b4e5",
	},
	{
		name: "plastics",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.plastics",
		color: "#459aa7",
	},
	{
		name: "polyurethane",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.polyurethane",
		color: "#4fb188",
	},
	{
		name: "steel",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.steel",
		color: "#4fb188",
	},
	{
		name: "others",
		translation: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.dataKeys.others",
		color: UiColors.charts.quaternary,
	},
];
