import { FC } from "react";
import { useTranslation } from "react-i18next";

import { AvgMaterialCompositionLargeHomeAppliancesTooltipProps } from "./AvgMaterialCompositionLargeHomeAppliancesTooltip.type";

const AvgMaterialCompositionLargeHomeAppliancesTooltip: FC<AvgMaterialCompositionLargeHomeAppliancesTooltipProps> = (props) => {
	const { t } = useTranslation();

	const { active, payload } = props;

	if (!active || !payload || !payload.length) {
		return null;
	}

	const data = payload[0] && payload[0].payload;

	return (
		<div className="p-3 m-0 bg-white border border-primary">
			<p className="text-xs">
				{t("pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.chart.tooltip", {
					material: props.material,
					value: new Intl.NumberFormat("nl-BE").format(data.value),
					category: t(`pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.chart.xAxisLabels.${data.category}`),
				})}
			</p>
		</div>
	);
};

export { AvgMaterialCompositionLargeHomeAppliancesTooltip };
