import { Props } from "recharts/types/component/Label";
import { CartesianViewBox } from "recharts/types/util/types";

export const getAttrsOfCartesianLabel = (props: Props) => {
	const { viewBox, parentViewBox, offset, position } = props;
	const { x, y, width, height } = viewBox as CartesianViewBox;

	// Define vertical offsets and position inverts based on the value being positive or negative
	const verticalSign = (height || 0) >= 0 ? 1 : -1;
	const verticalOffset = verticalSign * (offset || 0);
	const verticalEnd = verticalSign > 0 ? "end" : "start";
	const verticalStart = verticalSign > 0 ? "start" : "end";

	// Define horizontal offsets and position inverts based on the value being positive or negative
	const horizontalSign = (width || 0) >= 0 ? 1 : -1;
	const horizontalOffset = horizontalSign * (offset || 0);
	const horizontalEnd = horizontalSign > 0 ? "end" : "start";
	const horizontalStart = horizontalSign > 0 ? "start" : "end";

	if (position === "top") {
		const attrs = {
			x: (x || 0) + (width || 0) / 2,
			y: (y || 0) - verticalSign * (offset || 0),
			textAnchor: "middle",
			verticalAnchor: verticalEnd,
		};

		return {
			...attrs,
			...(parentViewBox
				? {
						height: Math.max((y || 0) - ((parentViewBox as CartesianViewBox).y || 0), 0),
						width,
				  }
				: {}),
		};
	}

	if (position === "bottom") {
		const attrs = {
			x: (x || 0) + (width || 0) / 2,
			y: (y || 0) + (height || 0) + verticalOffset,
			textAnchor: "middle",
			verticalAnchor: verticalStart,
		};

		return {
			...attrs,
			...(parentViewBox
				? {
						height: Math.max(
							((parentViewBox as CartesianViewBox).y || 0) + ((parentViewBox as CartesianViewBox).height || 0) - ((y || 0) + (height || 0)),
							0,
						),
						width,
				  }
				: {}),
		};
	}

	if (position === "left") {
		const attrs = {
			x: (x || 0) - horizontalOffset,
			y: (y || 0) + (height || 0) / 2,
			textAnchor: horizontalEnd,
			verticalAnchor: "middle",
		};

		return {
			...attrs,
			...(parentViewBox
				? {
						width: Math.max(attrs.x - ((parentViewBox as CartesianViewBox).x || 0), 0),
						height,
				  }
				: {}),
		};
	}

	if (position === "right") {
		const attrs = {
			x: (x || 0) + (width || 0) + horizontalOffset,
			y: (y || 0) + (height || 0) / 2,
			textAnchor: horizontalStart,
			verticalAnchor: "middle",
		};
		return {
			...attrs,
			...(parentViewBox
				? {
						width: Math.max(((parentViewBox as CartesianViewBox).x || 0) + ((parentViewBox as CartesianViewBox).width || 0) - attrs.x, 0),
						height,
				  }
				: {}),
		};
	}

	const sizeAttrs = parentViewBox ? { width, height } : {};

	if (position === "insideLeft") {
		return {
			x: (x || 0) + horizontalOffset,
			y: (y || 0) + (height || 0) / 2,
			textAnchor: horizontalStart,
			verticalAnchor: "middle",
			...sizeAttrs,
		};
	}

	if (position === "insideRight") {
		return {
			x: (x || 0) + (width || 0) - horizontalOffset,
			y: (y || 0) + (height || 0) / 2,
			textAnchor: horizontalEnd,
			verticalAnchor: "middle",
			...sizeAttrs,
		};
	}

	if (position === "insideTop") {
		return {
			x: (x || 0) + (width || 0) / 2,
			y: (y || 0) + verticalOffset,
			textAnchor: "middle",
			verticalAnchor: verticalStart,
			...sizeAttrs,
		};
	}

	if (position === "insideBottom") {
		return {
			x: (x || 0) + (width || 0) / 2,
			y: (y || 0) + (height || 0) - verticalOffset,
			textAnchor: "middle",
			verticalAnchor: verticalEnd,
			...sizeAttrs,
		};
	}

	if (position === "insideTopLeft") {
		return {
			x: (x || 0) + horizontalOffset,
			y: (y || 0) + verticalOffset,
			textAnchor: horizontalStart,
			verticalAnchor: verticalStart,
			...sizeAttrs,
		};
	}

	if (position === "insideTopRight") {
		return {
			x: (x || 0) + (width || 0) - horizontalOffset,
			y: (y || 0) + verticalOffset,
			textAnchor: horizontalEnd,
			verticalAnchor: verticalStart,
			...sizeAttrs,
		};
	}

	if (position === "insideBottomLeft") {
		return {
			x: (x || 0) + horizontalOffset,
			y: (y || 0) + (height || 0) - verticalOffset,
			textAnchor: horizontalStart,
			verticalAnchor: verticalEnd,
			...sizeAttrs,
		};
	}

	if (position === "insideBottomRight") {
		return {
			x: (x || 0) + (width || 0) - horizontalOffset,
			y: (y || 0) + (height || 0) - verticalOffset,
			textAnchor: horizontalEnd,
			verticalAnchor: verticalEnd,
			...sizeAttrs,
		};
	}

	return {
		x: (x || 0) + (width || 0) / 2,
		y: (y || 0) + (height || 0) / 2,
		textAnchor: "middle",
		verticalAnchor: "middle",
		...sizeAttrs,
	};
};
