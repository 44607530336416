import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/images/content/pillar-2/smart-homes-in-europe/smart-homes-in-europe-desktop.webp";
import Mobile from "../../../../assets/images/content/pillar-2/smart-homes-in-europe/smart-homes-in-europe-mobile.webp";

const SmartHomesInEurope: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col items-left justify-center">
			<img
				className="hidden xs:block w-full max-w-3xl"
				src={Desktop}
				alt={t("pages.pillar.pillar2.smartHomesInEurope.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar2.smartHomesInEurope.blocks.visual.alt") as string}
			/>
			<img
				className="block xs:hidden"
				src={Mobile}
				alt={t("pages.pillar.pillar2.smartHomesInEurope.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar2.smartHomesInEurope.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { SmartHomesInEurope };
