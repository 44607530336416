import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/vectors/content/pillar-2/large-appliances-sold-globally-in-2022/large-appliances-sold-globally-in-2022-desktop.svg";
import Mobile from "../../../../assets/vectors/content/pillar-2/large-appliances-sold-globally-in-2022/large-appliances-sold-globally-in-2022-mobile.svg";

const LargeAppliancesSoldGloballyIn2022: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col items-center justify-center">
			<img
				className="hidden sm:block w-full max-w-3xl"
				src={Desktop}
				alt={t("pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.visual.alt") as string}
			/>
			<img
				className="block sm:hidden"
				src={Mobile}
				alt={t("pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { LargeAppliancesSoldGloballyIn2022 };
