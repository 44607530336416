import { FC } from "react";
import { CartesianGrid, LabelList, Line, LineChart as RechartsLineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { UiColors } from "../../../config/ui/colors";

import { LineChartProps } from "./LineChart.type";

const LineChart: FC<LineChartProps> = ({
	data = [],
	dataKeys = [],
	height,
	marginLeft = -25,
	XAxisLabel,
	XAxisProps,
	YAxisLabel,
	YAxisProps,
	labelProps,
	Tooltip: CustomTooltip,
	showToolTip = true,
}) => {
	return (
		<div className="w-full">
			{YAxisLabel && <p className="text-xs text-black">{YAxisLabel}</p>}

			<ResponsiveContainer width="99%" height={height}>
				<RechartsLineChart data={data} margin={{ left: marginLeft, top: 20, right: 0, bottom: 0 }} layout="horizontal">
					<CartesianGrid stroke={UiColors.charts.grid} />

					<XAxis
						{...XAxisProps}
						axisLine={{ stroke: UiColors.black, ...(typeof XAxisProps?.axisLine === "object" ? XAxisProps.axisLine : {}) }}
						tickMargin={10}
						// Set interval to 0 to prevent tick labels & grid lines to disappear
						interval={0}
						tick={{ fill: UiColors.black, ...(typeof XAxisProps?.tick === "object" ? XAxisProps.tick : {}) }}
						tickLine={false}
					/>

					<YAxis
						{...YAxisProps}
						axisLine={{ stroke: UiColors.black, ...(typeof YAxisProps?.axisLine === "object" ? YAxisProps.axisLine : {}) }}
						tickMargin={10}
						tick={{ fill: UiColors.black, ...(typeof YAxisProps?.tick === "object" ? YAxisProps.tick : {}) }}
						tickLine={false}
					/>

					{dataKeys.map((line) => (
						<Line
							key={`${line.name} - ${line.color}`}
							type="linear"
							dataKey={line.name}
							strokeWidth={1}
							stroke={line.color}
							dot={{ strokeWidth: 4, fill: line.color }}
							isAnimationActive={false}
						>
							{line?.showLabel && (
								<LabelList offset={12} fontWeight="bold" dataKey={line.name} position="top" fill={line.color} {...labelProps} />
							)}
						</Line>
					))}

					{typeof CustomTooltip !== "undefined" && (
						<Tooltip
							cursor={false}
							wrapperStyle={{ zIndex: 100 }}
							content={(props) => <CustomTooltip {...props} />}
							isAnimationActive={false}
						/>
					)}

					{typeof CustomTooltip === "undefined" && showToolTip ? <Tooltip /> : null}
				</RechartsLineChart>
			</ResponsiveContainer>

			{XAxisLabel && <p className="mt-3 text-xs text-black text-center">{XAxisLabel}</p>}
		</div>
	);
};

export { LineChart };
