import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { AppliaRoute } from "../../../../config/routes/routes";
import { getRouteInfo } from "../../../../utils/router/getRouteInfo/getRouteInfo";
import { isInViewport } from "../../../../utils/viewport/isInViewport";

export const useSidebarScrollPositionOnPageLoad = (routes: AppliaRoute[], mobile: boolean) => {
	const location = useLocation();

	const itemsRef = useRef<Map<string, HTMLButtonElement | HTMLLIElement>>();

	const scrollToId = useCallback(
		(itemId: string) => {
			const node = itemsRef.current?.get(itemId);

			if (node && !isInViewport(node)) {
				node.scrollIntoView({ block: "start", inline: "nearest" });
			}
		},
		[itemsRef],
	);

	// Scroll active route in to view on page load
	useEffect(() => {
		if (mobile) {
			return;
		}

		const currentRouteCollapsibleInfo = getRouteInfo(routes, location.pathname);

		setTimeout(() => {
			scrollToId(currentRouteCollapsibleInfo?.label || "");
		}, 100);
	}, [routes, mobile, scrollToId, itemsRef, location]);

	return {
		itemsRef,
	};
};
