import { FC } from "react";
import { LabelProps } from "recharts";

import { filterAxisProps } from "./CustomizedYAxisLabel.helper";

const CustomizedYAxisLabel: FC<LabelProps> = (props: LabelProps) => {
	return (
		<g>
			<text fontSize={`${props.fontSize || 14}px`} textAnchor="start" {...filterAxisProps(props)}>
				{props.value}
			</text>
		</g>
	);
};

export { CustomizedYAxisLabel };
