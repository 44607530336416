/* BG IMAGES */
import BgAcceleratingEuropesGrowth from "../../assets/images/content/pillar-2/index/bg-header.webp";
import BgAdvancingSustainableLifestyles from "../../assets/images/content/pillar-1/index/bg-header.webp";
import BgAppliaSecretariat from "../../assets/images/content/introduction/applia-secretariat/bg-header.webp";
import BgDirectMembers from "../../assets/images/content/introduction/direct-members/bg-header.webp";
import BgEuropeApplianceIndustryIn2022 from "../../assets/images/content/introduction/home-appliance-industry-in-europe-in-2022/bg-header.webp";
import BgForeword from "../../assets/images/content/introduction/foreword/bg-header.webp";
import BgNationalAssociations from "../../assets/images/content/introduction/national-associations/bg-header.webp";
import BgPolicyDevelopments from "../../assets/images/content/introduction/policy-developments-in-2022/bg-header.webp";
import BgProducts from "../../assets/images/content/introduction/products/bg-header.webp";
import BgWhatToExpect from "../../assets/images/content/introduction/what-to-expect-in-2023/bg-header.webp";

/* OTHER IMAGES */
import AverageMaterialCompositionOfHomeAppliancesVisual from "../../assets/images/content/pillar-1/average-material-composition-of-home-appliances/average-material-composition-of-home-appliances-visual.webp";
import CriticalMaterials from "../../assets/images/content/introduction/what-to-expect-in-2023/critical-materials-mining.webp";
import EnergyEfficiency from "../../assets/images/content/introduction/policy-developments-in-2022/energy-efficiency.webp";
import EuropeanCommission from "../../assets/images/content/introduction/what-to-expect-in-2023/european-commission.webp";
import GreenBuildings from "../../assets/images/content/introduction/what-to-expect-in-2023/green-buildings.webp";
import HakanBulgurLu from "../../assets/images/content/introduction/foreword/hakan_bulgurlu.jpg";
import HeatPumps from "../../assets/images/content/introduction/policy-developments-in-2022/heat-pumps.webp";
import HoardingOfWeeeVisual from "../../assets/vectors/content/pillar-1/hoarding-of-weee/Hoarding of WEEE.svg";
import PackagingDelivery from "../../assets/images/content/introduction/policy-developments-in-2022/packaging-delivery.webp";
import PaoloFalcioni from "../../assets/images/content/introduction/foreword/paolo_falcioni.jpg";
import ProductionLine from "../../assets/images/content/introduction/policy-developments-in-2022/production-line.webp";
import PurchasingProductClaims from "../../assets/images/content/introduction/what-to-expect-in-2023/purchasing-product-claims.webp";
import Repair from "../../assets/images/content/introduction/what-to-expect-in-2023/repair.webp";
import RepairPillar1 from "../../assets/images/content/pillar-1/index/repair.webp";
import SmartAppliances from "../../assets/images/content/introduction/policy-developments-in-2022/smart-appliances-data.webp";
import SmartHome from "../../assets/images/content/pillar-2/index/smart-home.webp";
import Sustainability from "../../assets/images/content/introduction/policy-developments-in-2022/sustainability.webp";
import Trade from "../../assets/images/content/introduction/policy-developments-in-2022/trade.webp";
import TradePillar2 from "../../assets/images/content/pillar-2/index/trade.webp";

/* COMPONENT IMPORTS */
import { ContentBlock } from "../../components/ContentBuilder/ContentBuilder.type";

// Introduction custom components
import { AppliaSecretariat } from "../../components/CustomPageBlocks/introduction/AppliaSecretariat/AppliaSecretariat";
import { NationalAssociations } from "../../components/CustomPageBlocks/introduction/NationalAssociations/NationalAssociations";

// Introduction data files
import { AppliaSecretariatMembers } from "../../assets/data/content/introduction/applia-secretariat/data";
import { directMembers } from "../../assets/data/content/introduction/direct-members/data";

// Pillar 1 custom components
import { AvgMaterialCompositionLargeHomeAppliances } from "../../components/CustomPageBlocks/pillar1/AvgMaterialCompositionLargeHomeAppliances/AvgMaterialCompositionLargeHomeAppliances";
import { AvgMaterialCompositionSmallHomeAppliances } from "../../components/CustomPageBlocks/pillar1/AvgMaterialCompositionSmallHomeAppliances/AvgMaterialCompositionSmallHomeAppliances";
import { EnergyConsumptionInTheHomeIn2021 } from "../../components/CustomPageBlocks/pillar1/EnergyConsumptionInTheHomeIn2021/EnergyConsumptionInTheHomeIn2021";
import { HomeApplianceIndustryInEuropeIn2022 } from "../../components/CustomPageBlocks/introduction/HomeApplianceIndustryInEuropeIn2022/HomeApplianceIndustryInEuropeIn2022";
import { MaterialsRecoveredFromWaste } from "../../components/CustomPageBlocks/pillar1/MaterialsRecoveredFromWaste/MaterialsRecoveredFromWaste";
import { MaterialUseDuringTheProductionProcess } from "../../components/CustomPageBlocks/pillar1/MaterialUseDuringTheProductionProcess/MaterialUseDuringTheProductionProcess";
import { PlasticFlowsFromHomeAppliances } from "../../components/CustomPageBlocks/pillar1/PlasticFlowsFromHomeAppliances/PlasticFlowsFromHomeAppliances";
import { Products } from "../../components/CustomPageBlocks/introduction/Products/Products";
import { RepairOfHomeAppliances } from "../../components/CustomPageBlocks/pillar1/RepairOfHomeAppliances/RepairOfHomeAppliances";
import { RoutesOfRecycledPlastic } from "../../components/CustomPageBlocks/pillar1/RoutesOfRecycledPlastic/RoutesOfRecycledPlastic";
import { WeeeFlowInEurope } from "../../components/CustomPageBlocks/pillar1/WeeeFlowInEurope/WeeeFlowInEurope";
import { WeeeFlowInTheHomeApplianceSector } from "../../components/CustomPageBlocks/pillar1/WeeeFlowInTheHomeApplianceSector/WeeeFlowInTheHomeApplianceSector";
import { WeeeGeneratedByEuMemberStates } from "../../components/CustomPageBlocks/pillar1/WeeeGeneratedByEuMemberStates/WeeeGeneratedByEuMemberStates";
import { WeeeGeneratedByInhabitants } from "../../components/CustomPageBlocks/pillar1/WeeeGeneratedByInhabitants/WeeeGeneratedByInhabitants";
import { WeeeGeneratedInEurope } from "../../components/CustomPageBlocks/pillar1/WeeeGeneratedInEurope/WeeeGeneratedInEurope";

// Pillar 1 data files
import AvgMaterialCompositionLargeHomeAppliancesData from "../../assets/data/content/pillar-1/avg-material-composition-large-home-appliances/data.json";
import AvgMaterialCompositionSmallHomeAppliancesData from "../../assets/data/content/pillar-1/avg-material-composition-small-home-appliances/data.json";
import MaterialsRecoveredFromWasteData from "../../assets/data/content/pillar-1/materials-recovered-from-waste/data.json";
import RepairOfHomeAppliancesData from "../../assets/data/content/pillar-1/repair-of-home-appliances/data.json";
import WeeeFlowInTheHomeApplianceSectorBarChartData from "../../assets/data/content/pillar-1/weee-flow-in-the-home-appliance-sector/bar-chart-data.json";
import WeeeFlowInTheHomeApplianceSectorLineChartData from "../../assets/data/content/pillar-1/weee-flow-in-the-home-appliance-sector/line-chart-data.json";
import WeeeGeneratedByEuMemberStatesData from "../../assets/data/content/pillar-1/weee-generated-by-eu-member-states/data.json";
import WeeeGeneratedInEuropeData from "../../assets/data/content/pillar-1/weee-generated-in-europe/data.json";

// Pillar 2 custom components
import { AverageExpenditureOfHouseholds } from "../../components/CustomPageBlocks/pillar2/AverageExpenditureOfHouseholds/AverageExpenditureOfHouseholds";
import { DirectMembersManufacturingSitesInEurope } from "../../components/CustomPageBlocks/pillar2/DirectMembersManufacturingSitesInEurope/DirectMembersManufacturingSitesInEurope";
import { EeePlacedOnTheEuMarket } from "../../components/CustomPageBlocks/pillar2/EeePlacedOnTheEuMarket/EeePlacedOnTheEuMarket";
import { EvolutionOfImportAndExportOfHomeAppliancesInEurope } from "../../components/CustomPageBlocks/pillar2/EvolutionOfImportAndExportOfHomeAppliancesInEurope/EvolutionOfImportAndExportOfHomeAppliancesInEurope";
import { Top10ImportAndExportCountries } from "../../components/CustomPageBlocks/pillar2/Top10ImportAndExportCountries/Top10ImportAndExportCountries";
import { ImportAndExportOfHomeAppliancesInEuropeByContinent } from "../../components/CustomPageBlocks/pillar2/ImportAndExportOfHomeAppliancesInEuropeByContinent/ImportAndExportOfHomeAppliancesInEuropeByContinent";
import { InvestmentInRAndDByEuMemberStates } from "../../components/CustomPageBlocks/pillar2/InvestmentInRAndDByEuMemberStates/InvestmentInRAndDByEuMemberStates";
import { InvestmentTrendsInRDByEuMemberStates } from "../../components/CustomPageBlocks/pillar2/InvestmentTrendsInRdByEuMemberStates/InvestmentTrendsInRDByEuMemberStates";
import { LargeAppliancesSoldGloballyIn2022 } from "../../components/CustomPageBlocks/pillar2/LargeAppliancesSoldGloballyIn2022/LargeAppliancesSoldGloballyIn2022";
import { PatentsFiledAndGrantedForHomeAppliances } from "../../components/CustomPageBlocks/pillar2/PatentsFiledAndGrantedForHomeAppliances/PatentsFiledAndGrantedForHomeAppliances";
import { SmartHomesInEurope } from "../../components/CustomPageBlocks/pillar2/SmartHomesInEurope/SmartHomesInEurope";
import { UnitsTradedInEurope } from "../../components/CustomPageBlocks/pillar2/UnitsTradedInEurope/UnitsTradedInEurope";

// Pillar 2 data files
import AverageExpenditureOfHouseholdsData from "../../assets/data/content/pillar-2/average-expenditure-of-households/data.json";
import CountryTradeObjects from "../../assets/data/content/pillar-2/shared/CountryTradeData.json";
import DirectMembersManufacturingSitesInEuropeData from "../../assets/data/content/pillar-2/direct-members-manufacturing-sites-in-europe/data.json";
import EeePlacedOnTheEuMarketData from "../../assets/data/content/pillar-2/eee-placed-on-the-eu-market/data.json";
import EUTradeObjects from "../../assets/data/content/pillar-2/shared/EUTradeData.json";
import PatentsFiledAndGrantedForHomeAppliancesData from "../../assets/data/content/pillar-2/patents-filed-and-granted-for-home-appliances/data.json";

export interface PageContentSource {
	label: string;
	url?: string;
}

export interface PagePdf {
	path: string;
	filename?: string;
}

export interface PageContent {
	heroIntro?: string;
	heroImage?: string;
	pageIntro?: string;
	blocks: ContentBlock[];
	source?: PageContentSource | Array<Required<PageContentSource>>;
	pdf?: PagePdf;
}

export const pageContent: { [key: string]: PageContent } = {
	"navigation.introduction.foreword": {
		heroImage: BgForeword,
		heroIntro: "pages.introduction.foreword.heroIntro",
		blocks: [
			{
				type: "text-image",
				id: "foreword-hakan-bulgurlu",
				data: {
					text: ["pages.introduction.foreword.blocks.hakanBulgurlu.paragraph"],
					title: "pages.introduction.foreword.blocks.hakanBulgurlu.title",
					type: "image-left",
					imageAlt: "pages.introduction.foreword.blocks.hakanBulgurlu.imageAlt",
					imageUrl: HakanBulgurLu,
					imageSize: "normal",
				},
			},
			{
				type: "text-image",
				id: "foreword-paolo-falcioni",
				data: {
					text: ["pages.introduction.foreword.blocks.paoloFalcioni.paragraph"],
					title: "pages.introduction.foreword.blocks.paoloFalcioni.title",
					type: "image-left",
					imageAlt: "pages.introduction.foreword.blocks.paoloFalcioni.imageAlt",
					imageUrl: PaoloFalcioni,
					imageSize: "normal",
				},
			},
		],
	},
	"navigation.introduction.appliaDirectMembers": {
		heroImage: BgDirectMembers,
		heroIntro: "pages.introduction.appliaDirectMembers.heroIntro",
		blocks: [
			{
				id: "applia-direct-members-intro",
				type: "text",
				data: {
					text: [
						"pages.introduction.appliaDirectMembers.blocks.intro.paragraph1",
						"pages.introduction.appliaDirectMembers.blocks.intro.paragraph2",
					],
				},
			},
			{
				id: "applia-direct-members-grid",
				type: "image-grid",
				data: {
					cols: 2,
					responsiveCols: {
						xs: 3,
						md: 4,
					},
					items: directMembers,
				},
			},
		],
	},
	"navigation.introduction.nationalAssociations": {
		heroImage: BgNationalAssociations,
		heroIntro: "pages.introduction.nationalAssociations.heroIntro",
		blocks: [
			{
				type: "custom",
				id: "pages.introduction.nationalAssociations.map",
				data: {
					component: NationalAssociations,
				},
			},
		],
	},
	"navigation.introduction.appliaSecretariat": {
		heroImage: BgAppliaSecretariat,
		blocks: [
			{
				id: "navigation-introduction-appliaSecretariat-custom",
				type: "custom",
				data: {
					component: AppliaSecretariat,
					props: {
						members: AppliaSecretariatMembers,
					},
				},
			},
		],
	},
	"navigation.introduction.europeHomeApplianceIndustryIn2022": {
		heroImage: BgEuropeApplianceIndustryIn2022,
		blocks: [
			{
				id: "pages.introduction.europeHomeApplianceIndustryIn2022.blocks.data",
				type: "custom",
				data: {
					component: HomeApplianceIndustryInEuropeIn2022,
				},
			},
		],
	},
	"navigation.introduction.products": {
		heroIntro: "pages.introduction.products.pageIntro",
		heroImage: BgProducts,
		blocks: [
			{
				type: "custom",
				id: "pages.introduction.products.blocks.visual",
				data: {
					component: Products,
				},
			},
		],
	},
	"navigation.introduction.policyDevelopmentsIn2022": {
		heroImage: BgPolicyDevelopments,
		pageIntro: "pages.introduction.policyDevelopmentsIn2022.pageIntro",
		blocks: [
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability-text-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					type: "image-left",
					imageUrl: Sustainability,
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.espr.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.espr.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.espr.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.batteries-regulation",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.batteries-regulation.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.batteries-regulation.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.ppwr-text-image",
				type: "text-image",
				data: {
					imageUrl: PackagingDelivery,
					imageSize: "normal",
					type: "image-right",
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.ppwr.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.ppwr.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.ppwr.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.fitForPurposeEvaluationOfWeeeDirective",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.fitForPurposeEvaluationOfWeeeDirective.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.fitForPurposeEvaluationOfWeeeDirective.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.sustainability.fitForPurposeEvaluationOfWeeeDirective.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.consumerPolicy",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.consumerPolicy.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.consumerPolicy.paragraph1",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate-text-image",
				type: "text-image",
				data: {
					imageUrl: ProductionLine,
					imageSize: "normal",
					type: "image-left",
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.eprel.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.corporate.eprel.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.corporate.eprel.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.eu-taxonomy",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.eu-taxonomy.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.eu-taxonomy.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.directive-on-corporate-sustainability-due-diligence",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.directive-on-corporate-sustainability-due-diligence.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.corporate.directive-on-corporate-sustainability-due-diligence.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.energy",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.energy-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					imageUrl: EnergyEfficiency,
					type: "image-right",
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.REPowerEU-plan.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.energy.REPowerEU-plan.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.energy.REPowerEU-plan.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.f-gas-regulation-review",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.f-gas-regulation-review.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.f-gas-regulation-review.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.recast-directive-on-energy-efficiency-text-image",
				type: "text-image",
				data: {
					imageUrl: HeatPumps,
					imageSize: "normal",
					type: "image-left",
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.recast-directive-on-energy-efficiency.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.energy.recast-directive-on-energy-efficiency.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.energy.recast-directive-on-energy-efficiency.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.renewable-energy-directive",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.renewable-energy-directive.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.energy.renewable-energy-directive.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cbam-text-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					imageUrl: Trade,
					type: "image-right",
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cbam.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cbam.paragraph1",
						"pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cbam.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.eu-data-act",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.eu-data-act.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.eu-data-act.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cra-text-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					imageUrl: SmartAppliances,
					type: "image-left",
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cra.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.cra.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.ai-act",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.ai-act.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.ai-act.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.eu-chips-act",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.eu-chips-act.title",
					text: "pages.introduction.policyDevelopmentsIn2022.blocks.digitalisation-trade.eu-chips-act.paragraph",
				},
			},
		],
	},
	"navigation.introduction.whatToExpectIn2023": {
		heroImage: BgWhatToExpect,
		pageIntro: "pages.introduction.whatToExpectIn2023.pageIntro",
		blocks: [
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.intro",
				type: "text",
				data: {
					title: "pages.introduction.whatToExpectIn2023.blocks.intro.title",
					text: "pages.introduction.whatToExpectIn2023.blocks.intro.paragraph",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.green-deal-industrial-plan-image-text",
				type: "text-image",
				data: {
					imageUrl: GreenBuildings,
					imageSize: "big",
					type: "image-left",
					text: "pages.introduction.whatToExpectIn2023.blocks.green-deal-industrial-plan.paragraph",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.electricity-market-design-text-image",
				type: "text-image",
				data: {
					imageUrl: EuropeanCommission,
					imageSize: "normal",
					type: "image-right",
					text: "pages.introduction.whatToExpectIn2023.blocks.electricity-market-design.paragraph",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.right-to-repair-text-image",
				type: "text-image",
				data: {
					text: "pages.introduction.whatToExpectIn2023.blocks.right-to-repair.paragraph1",
					imageUrl: Repair,
					imageSize: "big",
					type: "image-left",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.right-to-repair-text",
				type: "text",
				data: {
					text: "pages.introduction.whatToExpectIn2023.blocks.right-to-repair.paragraph2",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.critical-raw-materials-text",
				type: "text",
				data: {
					text: "pages.introduction.whatToExpectIn2023.blocks.critical-raw-materials.paragraph1",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.critical-raw-materials-text-image",
				type: "text-image",
				data: {
					text: "pages.introduction.whatToExpectIn2023.blocks.critical-raw-materials.paragraph2",
					imageUrl: CriticalMaterials,
					imageSize: "big",
					type: "image-right",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.green-claims-directive",
				type: "text-image",
				data: {
					text: "pages.introduction.whatToExpectIn2023.blocks.green-claims-directive.paragraph",
					imageUrl: PurchasingProductClaims,
					imageSize: "normal",
					type: "image-left",
				},
			},
			{
				id: "pages.introduction.whatToExpectIn2023.blocks.outro",
				type: "text",
				data: {
					text: [
						"pages.introduction.whatToExpectIn2023.blocks.pfas.paragraph",
						"pages.introduction.whatToExpectIn2023.blocks.reach.paragraph",
						"pages.introduction.whatToExpectIn2023.blocks.outro.paragraph",
					],
				},
			},
		],
	},
	/* 📝 PILLAR 1 */
	"navigation.pillar1.index": {
		heroImage: BgAdvancingSustainableLifestyles,
		heroIntro: "pages.pillar.pillar1.index.heroIntro",
		pageIntro: "pages.pillar.pillar1.index.pageIntro",
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.index.blocks.intro",
				data: {
					text: [
						"pages.pillar.pillar1.index.blocks.intro.paragraph1",
						"pages.pillar.pillar1.index.blocks.intro.paragraph2",
						"pages.pillar.pillar1.index.blocks.intro.paragraph3",
					],
				},
			},
			{
				id: "pages.pillar.pillar1.index.blocks.repair-image",
				type: "text-image",
				data: {
					text: ["pages.pillar.pillar1.index.blocks.repair-image.paragraph1", "pages.pillar.pillar1.index.blocks.repair-image.paragraph2"],
					type: "image-left",
					imageSize: "normal",
					imageUrl: RepairPillar1,
				},
			},
			{
				id: "pages.pillar.pillar1.index.blocks.repair",
				type: "text",
				data: {
					text: ["pages.pillar.pillar1.index.blocks.repair.paragraph1"],
				},
			},
		],
	},
	"navigation.pillar1.materialUseDuringTheProductionProcess": {
		source: {
			label: "dss+ (formerly Sofies)",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-MaterialUseDuringTheProductionProcess.pdf",
			filename: "APPLiA - Material use during the production process",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.intro",
				type: "text",
				data: {
					text: "pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual",
				data: {
					component: MaterialUseDuringTheProductionProcess,
				},
			},
		],
	},
	"navigation.pillar1.averageMaterialCompositionOfHomeAppliances": {
		source: {
			label: "dss+ (formerly Sofies)",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageMaterialCompositionOfHomeAppliances.pdf",
			filename: "APPLiA - Average material composistion of home appliances",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.intro",
				type: "text",
				data: {
					text: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.intro.paragraph1",
				},
			},
			{
				type: "image",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.visual",
				data: {
					imageUrl: AverageMaterialCompositionOfHomeAppliancesVisual,
					imageAlt: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.visual.alt",
					imageSize: "normal",
					imageAlignment: "center",
				},
			},
		],
	},
	"navigation.pillar1.averageMaterialCompositionOfSmallHomeAppliances": {
		source: {
			label: "dss+ (formerly Sofies)",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageMaterialCompositionOfSmallHomeAppliances.pdf",
			filename: "APPLiA - Average material composistion of small home appliances",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.blocks.intro",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.chart",
				data: {
					component: AvgMaterialCompositionSmallHomeAppliances,
					props: {
						data: AvgMaterialCompositionSmallHomeAppliancesData,
					},
				},
			},
		],
	},
	"navigation.pillar1.averageMaterialCompositionOfLargeHomeAppliances": {
		source: {
			label: "dss+ (formerly Sofies)",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageMaterialCompositionOfLargeHomeAppliances.pdf",
			filename: "APPLiA - Average material composistion of large home appliances",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.intro",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances",
				data: {
					component: AvgMaterialCompositionLargeHomeAppliances,
					props: {
						data: AvgMaterialCompositionLargeHomeAppliancesData,
					},
				},
			},
		],
	},
	"navigation.pillar1.plasticFlowsFromHomeAppliances": {
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-PlasticFlowsFromHomeAppliances.pdf",
			filename: "APPLiA - Plastic flows from home appliances",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.visual",
				data: {
					component: PlasticFlowsFromHomeAppliances,
				},
			},
			{
				id: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.alert",
				type: "alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.alert.message",
				},
			},
		],
	},
	"navigation.pillar1.routesOfRecycledPlastic": {
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-RoutesOfRecycledPlastic.pdf",
			filename: "APPLiA - Routes of recycled plastic",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.visual",
				data: {
					component: RoutesOfRecycledPlastic,
				},
			},
		],
	},
	"navigation.pillar1.energyConsumptionInTheHomeIn2021": {
		source: {
			label: "Eurostat",
			url: "https://ec.europa.eu/eurostat/databrowser/view/nrg_d_hhq/default/table?lang=en",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-EnergyConsumptionInTheHomeIn2021.pdf",
			filename: "APPLiA - Energy consumption in the home in 2021",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.intro",
				type: "text",
				data: {
					text: "pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.visual",
				data: {
					component: EnergyConsumptionInTheHomeIn2021,
				},
			},
		],
	},
	"navigation.pillar1.repairOfHomeAppliances": {
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-RepairOfHomeAppliances.pdf",
			filename: "APPLiA - Repair of home appliances",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.visual",
				data: {
					component: RepairOfHomeAppliances,
					props: {
						data: RepairOfHomeAppliancesData,
					},
				},
			},
			{
				type: "alert",
				id: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.repairOfHomeAppliances.blocks.alert.message",
				},
			},
		],
	},
	"navigation.pillar1.weeeFlowInEurope": {
		source: {
			label:
				"dss+ (formerly Sofies) calculations based on Eurostat data, collection rate based on Eurostat data, waste bin and complementary flows on Prosum data and Ecotic study, quantities of WEEE generated based on apparent consumption methodology",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-WEEEFlowInEurope.pdf",
			filename: "APPLiA - WEEEE flow in Europe",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.weeeFlowInEurope.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.weeeFlowInEurope.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.weeeFlowInEurope.blocks.visual",
				data: {
					component: WeeeFlowInEurope,
				},
			},
		],
	},
	"navigation.pillar1.weeeGeneratedInEurope": {
		source: {
			label: "dss+ (formerly Sofies) calculations based on UNU-VIE SCYCLE and APPLiA Members data",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-WEEEGeneratedInEurope.pdf",
			filename: "APPLiA - WEEEE generated in Europe",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.chart",
				data: {
					component: WeeeGeneratedInEurope,
					props: {
						data: WeeeGeneratedInEuropeData,
					},
				},
			},
		],
	},
	"navigation.pillar1.weeeGeneratedByEuMemberStates": {
		source: {
			label:
				"dss+ (formerly Sofies) calculations based on Eurostat data, collection rate based on Eurostat data, waste bin and complementary flows on Prosum data and Ecotic study, quantities of WEEE generated based on apparent consumption methodology",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-WEEEGeneratedByEUMemberStates.pdf",
			filename: "APPLiA - WEEEE generated by EU memeber states",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.weeeGeneratedByEuMemberStates.blocks.chart",
				data: {
					component: WeeeGeneratedByEuMemberStates,
					props: {
						data: WeeeGeneratedByEuMemberStatesData,
					},
				},
			},
		],
	},
	"navigation.pillar1.weeeGeneratedByInhabitants": {
		source: [
			{
				label: "Eurostat (online data code: env_waseleeos for data for 2018 onwards)",
				url: "https://ec.europa.eu/eurostat/databrowser/view/env_waseleeos/default/table?lang=en",
			},
			{
				label: "Eurostat (online data code env_waselee for data prior to 2018)",
				url: "https://ec.europa.eu/eurostat/databrowser/view/env_waselee/default/table?lang=en",
			},
		],
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-WEEEGeneratedByInhabitants.pdf",
			filename: "APPLiA - WEEEE generated by inhabitants",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.chart",
				data: {
					component: WeeeGeneratedByInhabitants,
				},
			},
			{
				type: "alert",
				id: "pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.alert.message",
				},
			},
		],
	},
	"navigation.pillar1.hoardingOfWeee": {
		source: {
			label: "WEEE Forum data, dss+ (formerly Sofies)",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-HoardingOfWEEE.pdf",
			filename: "APPLiA - Hoarding of WEEE",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.hoardingOfWeee.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.hoardingOfWeee.blocks.intro.paragraph1",
				},
			},
			{
				type: "image",
				id: "pages.pillar.pillar1.hoardingOfWeee.blocks.visual",
				data: {
					imageSize: "big",
					imageAlignment: "center",
					imageUrl: HoardingOfWeeeVisual,
					imageAlt: "pages.pillar.pillar1.hoardingOfWeee.blocks.visual.alt",
				},
			},
		],
	},
	"navigation.pillar1.weeeFlowInTheHomeApplianceSector": {
		source: {
			label:
				"dss+ (formerly Sofies) calculations based on Eurostat data, collection rate based on Eurostat data, waste bin and complementary flows on Prosum data and Ecotic study, quantities of WEEE generated based on apparent consumption methodology",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-WEEEFlowInTheHomeApplianceSector.pdf",
			filename: "APPLiA - WEEEE flow in the home appliances sector",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.visual",
				data: {
					component: WeeeFlowInTheHomeApplianceSector,
					props: {
						lineChartData: WeeeFlowInTheHomeApplianceSectorLineChartData,
						barChartData: WeeeFlowInTheHomeApplianceSectorBarChartData,
					},
				},
			},
		],
	},
	"navigation.pillar1.materialsRecoveredFromWaste": {
		source: {
			label: "dss+ (formerly Sofies)",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-MaterialsRecoveredFromWaste.pdf",
			filename: "APPLiA - Materials recovered from waste",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual",
				data: {
					component: MaterialsRecoveredFromWaste,
					props: {
						data: MaterialsRecoveredFromWasteData,
					},
				},
			},
			{
				type: "alert",
				id: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.alert.message",
				},
			},
		],
	},
	/* 📝 PILLAR 2 */
	"navigation.pillar2.index": {
		heroImage: BgAcceleratingEuropesGrowth,
		heroIntro: "pages.pillar.pillar2.index.heroIntro",
		pageIntro: "pages.pillar.pillar2.index.pageIntro",
		blocks: [
			{
				id: "pages.pillar.pillar2.index.blocks.eu-single-market-image",
				type: "text-image",
				data: {
					text: [
						"pages.pillar.pillar2.index.blocks.eu-single-market-image.paragraph1",
						"pages.pillar.pillar2.index.blocks.eu-single-market-image.paragraph2",
					],
					imageSize: "normal",
					type: "image-left",
					imageUrl: TradePillar2,
				},
			},
			{
				type: "text",
				id: "pages.pillar.pillar2.index.blocks.eu-single-market",
				data: {
					text: ["pages.pillar.pillar2.index.blocks.eu-single-market.paragraph1"],
				},
			},
			{
				type: "text-image",
				id: "pages.pillar.pillar2.index.blocks.patents-image",
				data: {
					imageUrl: SmartHome,
					imageSize: "normal",
					type: "image-right",
					text: [
						"pages.pillar.pillar2.index.blocks.patents-image.paragraph1",
						"pages.pillar.pillar2.index.blocks.patents-image.paragraph2",
						"pages.pillar.pillar2.index.blocks.patents-image.paragraph3",
					],
				},
			},
		],
	},
	"navigation.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket": {
		source: {
			label: "UNU-VIE SCYCLE",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-ElectricalAndElectronicEquipmentPlacedOnTheEUMarket.pdf",
			filename: "APPLiA - Electrical and electronic equipment placed on the EU market",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket.blocks.custom",
				data: {
					component: EeePlacedOnTheEuMarket,
					props: {
						data: EeePlacedOnTheEuMarketData,
					},
				},
			},
		],
	},
	"navigation.pillar2.largeAppliancesSoldGloballyIn2022": {
		source: {
			label: "IRHMA",
			url: "https://drive.google.com/drive/u/2/folders/11ROQfg4JzAxe5WEWL3luYY7BLIMdem7m",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-LargeAppliancesSoldGloballyIn2022.pdf",
			filename: "APPLiA - Large appliances sold globally in 2022",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.largeAppliancesSoldGloballyIn2022.blocks.custom",
				data: {
					component: LargeAppliancesSoldGloballyIn2022,
				},
			},
		],
	},
	"navigation.pillar2.averageExpenditureOfHouseholds": {
		source: {
			label: "OECD data",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-AverageExpenditureOfHouseholds.pdf",
			filename: "APPLiA - Average expenditure of households",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-averageExpenditureOfHouseholds-chart",
				data: {
					component: AverageExpenditureOfHouseholds,
					props: {
						data: AverageExpenditureOfHouseholdsData,
					},
				},
			},
			{
				type: "alert",
				id: "pillar-2-averageExpenditureOfHouseholds-alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar2.averageExpenditureOfHouseholds.blocks.alert.message",
				},
			},
		],
	},
	"navigation.pillar2.unitsTradedInEurope": {
		source: {
			label: "APPLiA members data",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-UnitsTradedInEurope.pdf",
			filename: "APPLiA - Units traded in Europe",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.unitsTradedInEurope.blocks.intro",
				data: {
					text: [
						"pages.pillar.pillar2.unitsTradedInEurope.blocks.intro.paragraph1",
						"pages.pillar.pillar2.unitsTradedInEurope.blocks.section1.paragraph1",
						"pages.pillar.pillar2.unitsTradedInEurope.blocks.section2.paragraph1",
					],
				},
			},

			{
				type: "custom",
				id: "pages.pillar.pillar2.UnitsTradedInEurope.blocks.section1.visual",
				data: { component: UnitsTradedInEurope },
			},
		],
	},
	"navigation.pillar2.importAndExportOfHomeAppliancesInEuropeByContinent": {
		source: {
			label: "Eurostat - International trade in goods",
			url: "https://ec.europa.eu/eurostat/web/international-trade-in-goods/data/database",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-ImportAndExportOfHomeAppliancesInEurope.pdf",
			filename: "APPLiA - Import and export of home appliances in Europe",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-importAndExportOfHomeAppliancesInEuropeByContinent-chart",
				data: {
					component: ImportAndExportOfHomeAppliancesInEuropeByContinent,
					props: {
						data: EUTradeObjects,
					},
				},
			},
		],
	},
	"navigation.pillar2.top10ImportAndExportCountries": {
		source: {
			label: "Eurostat - International trade in goods",
			url: "https://ec.europa.eu/eurostat/web/international-trade-in-goods/data/database",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-Top10ImportAndExportCountries.pdf",
			filename: "APPLiA - Top 10 import and export countries",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-top10ImportAndExportCountries",
				data: {
					component: Top10ImportAndExportCountries,
					props: {
						data: CountryTradeObjects,
					},
				},
			},
		],
	},
	"navigation.pillar2.evolutionOfImportAndExportOfHomeAppliancesInEurope": {
		source: {
			label: "Eurostat - International trade in goods",
			url: "https://ec.europa.eu/eurostat/web/international-trade-in-goods/data/database",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-EvolutionOfImportAndExportOfHomeAppliancesInEurope.pdf",
			filename: "APPLiA - Evolution of import and export of home appliances in Europe",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-evolutionOfImportAndExportOfHomeAppliancesInEurope-chart",
				data: {
					component: EvolutionOfImportAndExportOfHomeAppliancesInEurope,
					props: {
						data: EUTradeObjects,
					},
				},
			},
		],
	},
	"navigation.pillar2.smartHomesInEurope": {
		source: {
			label: "Statista",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-SmartHomesInEurope.pdf",
			filename: "APPLiA - Smart homes in Europe",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.smartHomesInEurope.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.smartHomesInEurope.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.smartHomesInEurope.blocks.custom",
				data: {
					component: SmartHomesInEurope,
				},
			},
		],
	},
	"navigation.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2022": {
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-DirectMembersManufacturingSitesInEuropeIn2022.pdf",
			filename: "APPLiA - APPLiA Direct members manufacturing sites in Europe in 2022",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2022.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2022.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2022.blocks.chart",
				data: {
					component: DirectMembersManufacturingSitesInEurope,
					props: {
						data: DirectMembersManufacturingSitesInEuropeData,
					},
				},
			},
		],
	},
	"navigation.pillar2.patentsFiledAndGrantedForHomeAppliances": {
		source: {
			label: "Clarivate Analytics Derwent Innovation",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-PatentsFiledAndGrantedForHomeAppliances.pdf",
			filename: "APPLiA - Patents filed and granted for home appliances",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar2.patentsFiledAndGrantedForHomeAppliances.blocks.chart",
				data: {
					component: PatentsFiledAndGrantedForHomeAppliances,
					props: {
						data: PatentsFiledAndGrantedForHomeAppliancesData,
					},
				},
			},
		],
	},
	"navigation.pillar2.investmentInRAndDByEuMemberStates": {
		source: {
			label: "Eurostat (RD_E_GERDTOT) R&D related to business sector",
			url: "https://ec.europa.eu/eurostat/databrowser/view/RD_E_GERDTOT__custom_4667804/default/table?lang=en",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-InvestmentInRDByEUMemberStates.pdf",
			filename: "APPLiA - Investment in RD by EU member states",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.visual",
				data: {
					component: InvestmentInRAndDByEuMemberStates,
				},
			},
		],
	},
	"navigation.pillar2.investmentTrendsInRAndDByEuMemberStates20xx20xx": {
		source: {
			label: "Eurostat (RD_E_GERDTOT) R&D related to business sector",
			url: "https://ec.europa.eu/eurostat/databrowser/view/RD_E_GERDTOT__custom_4667804/default/table?lang=en",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-InvestmentTrendsInRDByEUMemberStates.pdf",
			filename: "APPLiA - Investement trends in RD by EU member states",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar2.investmentTrendsInRAndDByEuMemberStates20xx20xx.blocks.visual",
				data: {
					component: InvestmentTrendsInRDByEuMemberStates,
				},
			},
		],
	},
};
