import { LineChartDataKey } from "../../../Charts/LineChart/LineChart.type";
import { UiColors } from "../../../../config/ui/colors";

export const AVERAGE_EXPENDITURE_OF_HOUSEHOLDS_DATA_KEYS: Array<LineChartDataKey> = [
	{
		name: "percentage",
		translation: "pages.pillar.pillar2.averageExpenditureOfHouseholds.blocks.chart.dataKeys.percentage",
		color: UiColors.charts.secondary,
		showLabel: true,
	},
];
