export const WEEE_GENERATED_BY_INHABITANTS_LEGEND_ITEMS = [
	{
		label: "2013",
		color: "#54b7e8",
	},
	{
		label: "2018",
		color: "#2553a5",
	},
];
