import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/images/content/pillar-1/energy-consumption-in-the-home-in-2021/Energy-consumption-desktop.webp";
import Mobile from "../../../../assets/images/content/pillar-1/energy-consumption-in-the-home-in-2021/Energy-consumption-mobile.webp";

const EnergyConsumptionInTheHomeIn2021: FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="flex flex-col items-left justify-center pt-5 md:pt-0">
				<img
					className="hidden md:block w-full max-w-3xl"
					src={Desktop}
					alt={t("pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.visual.alt") as string}
					title={t("pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.visual.alt") as string}
				/>
				<img
					className="block md:hidden"
					src={Mobile}
					alt={t("pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.visual.alt") as string}
					title={t("pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.visual.alt") as string}
				/>
			</div>
		</>
	);
};

export { EnergyConsumptionInTheHomeIn2021 };
