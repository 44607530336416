import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { AreaChart } from "../../../Charts/AreaChart/AreaChart";
import { AreaLine } from "../../../Charts/AreaChart/AreaChart.type";
import { EUTradeObject } from "../../../Charts/shared/chart.type";
import { FilterDropdown } from "../shared/filter/FilterDropdown";
import { FilterInput } from "../shared/filter/FilterDropdown.type";
import { UiBreakpoints } from "../../../../config/ui/breakpoints";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { euColor, keys, worldColor } from "./EvolutionOfImportAndExportOfHomeAppliancesInEurope.const";
import { getFilteredData, getUnit, groupAreaData } from "./EvolutionOfImportAndExportOfHomeAppliancesInEurope.helper";

import { ExportAndImportInfoBox } from "./ExportAndImportInfoBox/ExportAndImportInfoBox";
import { EvolutionOfImportAndExportOfHomeAppliancesInEuropeProps } from "./EvolutionOfImportAndExportOfHomeAppliancesInEurope.type";

const EvolutionOfImportAndExportOfHomeAppliancesInEurope: FC<EvolutionOfImportAndExportOfHomeAppliancesInEuropeProps> = ({ data }) => {
	const [type, setType] = useState<string>("Export");
	const [category, setCategory] = useState<string>("Total");
	const [unit, setUnit] = useState<string>("valueInEuros");
	const [year, setYear] = useState<number>();

	const [euroDataSet, setEuroDataSet] = useState<EUTradeObject[]>();
	const [nonEuroDataSet, setNonEuroDataSet] = useState<EUTradeObject[]>();
	const [totalNonEuroDataSet, setTotalNonEuroDataSet] = useState<EUTradeObject[]>();

	const { width } = useWindowSize();

	// Fetches all EU27 data, depending on the current filter values
	const selectedEuroData = useMemo(() => {
		if (!euroDataSet || !type || !category || !unit) {
			return;
		}
		return getFilteredData(euroDataSet, type, category, unit);
	}, [category, euroDataSet, type, unit]);

	// Fetches all nonEU data, depending on the current filter values. This dataset is mostly used for the summary screen on the right
	const selectedNonEuroData = useMemo(() => {
		if (!nonEuroDataSet || !type || !category || !unit) {
			return;
		}
		return getFilteredData(nonEuroDataSet, type, category, unit);
	}, [category, nonEuroDataSet, type, unit]);

	// Fetches the sum data of all nonEu data, depending on the current filter values
	const selectedTotalNonEuroData = useMemo(() => {
		if (!totalNonEuroDataSet || !type || !category || !unit) {
			return;
		}
		return getFilteredData(totalNonEuroDataSet, type, category, unit);
	}, [category, totalNonEuroDataSet, type, unit]);

	const chartData = useMemo(() => {
		if (!selectedEuroData || !selectedTotalNonEuroData) {
			return;
		}
		const years = selectedEuroData.map((dataObject) => dataObject.period);

		return years.map((year) => {
			const euYearValue = selectedEuroData.find((euValue) => euValue.period === year);
			const worldYearValue = selectedTotalNonEuroData.find((worldValue) => worldValue.period === year);
			if (!euYearValue || !worldYearValue) {
				return;
			}
			return {
				name: year,
				euTradeValue: euYearValue.value,
				worldTradeValue: worldYearValue.value,
				euColor: euColor,
				worldColor: worldColor,
			};
		}) as AreaLine[];
	}, [selectedEuroData, selectedTotalNonEuroData]);

	const yAxisFormatter = useCallback(
		(tick: number | string) => {
			return `${new Intl.NumberFormat("nl-BE").format(Math.ceil(+tick / 1000000))} M${unit === "valueInEuros" ? "€" : "KG"}`;
		},
		[unit],
	);

	const handleDropdownChange = useCallback((selectors: FilterInput) => {
		const { type: selectedType, category: selectedCategory, unit: selectedUnit } = selectors;

		setType(selectedType);
		setCategory(selectedCategory);
		setUnit(getUnit(selectedUnit));
	}, []);

	const handleTooltipChange = useCallback((selectedYear: number) => {
		if (!selectedYear) {
			return;
		}
		setYear(selectedYear);
	}, []);

	useEffect(() => {
		const { euroData, nonEuroData, totalNonEuroData } = groupAreaData(data);
		if (!euroData || !nonEuroData || !totalNonEuroData) {
			return;
		}
		setEuroDataSet(euroData);
		setNonEuroDataSet(nonEuroData);
		setTotalNonEuroDataSet(totalNonEuroData);
	}, [data]);

	return (
		<>
			<div className="mb-12 md:mb-5">
				<FilterDropdown onChange={handleDropdownChange} />
			</div>
			<div className="flex flex-col xl:flex-row xl:items-end gap-12 xl:flex-nowrap">
				<div className="shrink grow-0 w-full xl:w-3/5 2xl:w-2/3">
					{chartData && (
						<AreaChart
							onChange={handleTooltipChange}
							data={chartData}
							keys={keys}
							margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
							height={width >= UiBreakpoints.lg ? 750 : 500}
							XAxisProps={{
								padding: { left: width >= UiBreakpoints["2xl"] ? 50 : 13, right: width >= UiBreakpoints["2xl"] ? 50 : 13 },
								tick: { width: 100, fontSize: width >= UiBreakpoints["2xl"] ? "16px" : "13px" },
								interval: 0,
							}}
							YAxisProps={{
								tickFormatter: yAxisFormatter,
								tick: { width: 100, fontSize: width >= UiBreakpoints["2xl"] ? "16px" : "14px" },
							}}
							stacked
						/>
					)}
				</div>
				<div className="grow shrink-0 w-full sm:w-3/4 xl:w-2/5 2xl:w-1/3 self-center xl:self-auto">
					{year && selectedEuroData && selectedNonEuroData && selectedTotalNonEuroData && (
						<ExportAndImportInfoBox
							period={year}
							euroData={selectedEuroData}
							nonEuroData={selectedNonEuroData}
							worldData={selectedTotalNonEuroData}
							unit={unit}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export { EvolutionOfImportAndExportOfHomeAppliancesInEurope };
