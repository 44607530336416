import { LineChartDataKey } from "../../../Charts/LineChart/LineChart.type";
import { UiColors } from "../../../../config/ui/colors";

export const PATENTS_FILED_AND_GRANTED_FOR_HOME_APPLIANCES_CHART_DATA_KEYS: Array<LineChartDataKey> = [
	{
		name: "filed",
		translation: "pages.pillar.pillar2.patentsFiledAndGrantedForHomeAppliances.blocks.chart.dataKeys.filed",
		color: UiColors.charts.secondary,
		showLabel: true,
	},
	{
		name: "granted",
		translation: "pages.pillar.pillar2.patentsFiledAndGrantedForHomeAppliances.blocks.chart.dataKeys.granted",
		color: UiColors.charts.primary,
		showLabel: true,
	},
];
