import { AppliaRoute } from "../../../config/routes/routes";

import { defaultValue } from "./getRouteSiblings.const";
import { flattenRoutes } from "./getRouteSiblings.helper";
import { SiblingRoutes } from "./getRouteSiblings.type";

export const getRouteSiblings = (currentRoute: string | undefined, routes: AppliaRoute[]): SiblingRoutes => {
	if (!Array.isArray(routes) || routes.length === 0) {
		return defaultValue;
	}

	if (!currentRoute) {
		return defaultValue;
	}

	const flattenedRoutes = flattenRoutes(routes);

	const currentRouteIndex = flattenedRoutes.findIndex((route) => route.url === currentRoute);

	const previousRouteIndex = flattenedRoutes[currentRouteIndex - 1];
	const nextRouteIndex = flattenedRoutes[currentRouteIndex + 1];

	return {
		previous: previousRouteIndex,
		next: nextRouteIndex,
	};
};
