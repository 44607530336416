import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { StackedHorizontalBarChart } from "../../../Charts/StackedHorizontalBarChart/StackedHorizontalBarChart";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { EEE_PLACED_ON_THE_EU_MARKET_CHART_DATA_KEYS } from "./EeePlacedOnTheEuMarket.const";
import { EeePlacedOnTheEuMarketProps } from "./EeePlacedOnTheEuMarket.type";

const EeePlacedOnTheEuMarket: FC<EeePlacedOnTheEuMarketProps> = ({ data }) => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	const [legendDirection, setLegendDirection] = useState<LegendProps["direction"]>("horizontal");
	const [barChartTickCount, setBarChartTickCount] = useState<number>(11);

	const chartData = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return data.sort((a, b) => +b.name - +a.name).map(({ euTotal, ...rest }) => ({ ...rest }));
	}, [data]);

	const euTotals = useMemo(() => {
		return data.sort((a, b) => +b.name - +a.name).map(({ euTotal }) => new Intl.NumberFormat("nl-BE").format(euTotal / 1000));
	}, [data]);

	const legendData = useMemo(() => {
		return EEE_PLACED_ON_THE_EU_MARKET_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setLegendDirection("vertical");
				setBarChartTickCount(3);
				break;

			case "sm":
				setLegendDirection("vertical");
				setBarChartTickCount(5);
				break;

			case "md":
				setLegendDirection("horizontal");
				setBarChartTickCount(5);
				break;

			case "lg":
			case "xl":
			default:
				setLegendDirection("horizontal");
				setBarChartTickCount(11);
				break;
		}
	}, [chartSize]);

	const barLabelFormatter = useCallback((value: number) => {
		return `${value}%`;
	}, []);

	return (
		<div className="flex flex-col gap-8">
			<div className="self-stretch sm:self-center">
				<Legend items={legendData} direction={legendDirection} />
			</div>
			<div className="flex flex-nowrap items-start gap-5 lg:gap-8">
				<div className="w-full grow shrink mt-6 md:mt-0">
					<p className="hidden md:block font-bold ml-11">Year</p>
					<StackedHorizontalBarChart
						data={chartData}
						dataKeys={EEE_PLACED_ON_THE_EU_MARKET_CHART_DATA_KEYS}
						YAxisProps={{
							dataKey: "name",
							padding: { top: 10, bottom: 10 },
							fontSize: 14,
						}}
						XAxisProps={{ unit: "%", tickCount: barChartTickCount, tick: { fontSize: 12 } }}
						barLabelFormatter={barLabelFormatter}
						showBarLabel={true}
					/>
				</div>
				<div className="self-stretch shrink-0 grow-0">
					<p className="font-bold mb-[50px] md:mb-10">EU total</p>
					<ul className="flex flex-col">
						{euTotals.map((yearTotal) => (
							<li key={yearTotal} className="h-6 flex text-right justify-end items-center mb-[43px] md:mb-[20px] text-sm md:text-base">
								{yearTotal} Mt
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export { EeePlacedOnTheEuMarket };
