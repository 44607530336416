import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CustomBlockProps } from "./CustomBlock.type";

const CustomBlock: FC<CustomBlockProps> = ({ id, data }) => {
	const { t } = useTranslation();

	const { title, component, props } = data;

	const Component = component;

	return (
		<div id={id}>
			{/* Optional: title */}
			{typeof title === "string" && title.trim().length > 0 ? <h3 dangerouslySetInnerHTML={{ __html: t(title) }} /> : null}

			<Component {...props} />
		</div>
	);
};

export { CustomBlock };
