import { FC } from "react";
import classNames from "classnames";

import { CardProps } from "./Card.type";

const Card: FC<CardProps> = ({ children, className, fluid = false }) => {
	return (
		<div
			className={classNames(
				"shadow-md rounded-md overflow-hidden",
				{
					"p-4": !fluid,
				},
				className,
			)}
		>
			{children}
		</div>
	);
};

export { Card };
