import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MatrixDiagram } from "../../../Charts/MatrixDiagram/MatrixDiagram";

import { AvgMaterialCompositionSmallHomeAppliancesProps } from "./AvgMaterialCompositionSmallHomeAppliances.type";
import { AvgMaterialCompositionSmallHomeAppliancesTooltip } from "./AvgMaterialCompositionSmallHomeAppliancesTooltip/AvgMaterialCompositionSmallHomeAppliancesTooltip";

const AvgMaterialCompositionSmallHomeAppliances: FC<AvgMaterialCompositionSmallHomeAppliancesProps> = ({ data }) => {
	const { t } = useTranslation();

	const xAxisLabels = useMemo(() => {
		if (!Array.isArray(Object.values(data)[0])) {
			return [];
		}

		const categories = Object.values(data)[0]?.map((entry) => entry.category);

		return categories.map((entry) =>
			t(`pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.blocks.chart.xAxisLabels.${entry}`),
		);
	}, [data, t]);

	return (
		<div>
			<MatrixDiagram
				data={data}
				titles={xAxisLabels}
				Tooltip={AvgMaterialCompositionSmallHomeAppliancesTooltip}
				yAxisTranslationLabelPrefix="pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.blocks.chart.yAxisLabels"
				range={[0, 4000]}
			/>
		</div>
	);
};

export { AvgMaterialCompositionSmallHomeAppliances };
