import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { ContentOverview } from "./routes/ContentOverview/ContentOverview";
import { GeneralErrorPage } from "./routes/Error/General/GeneralError";
import { Home } from "./routes/Home/Home";
import { IntroductionDetail } from "./routes/IntroductionDetail/IntroductionDetail";
import { Layout } from "./components/Layout/Layout";
import { NotFoundErrorPage } from "./routes/Error/NotFound/NotFoundError";
import { Pillar } from "./routes/Pillar/Pillar";
import { PillarDetail } from "./routes/Pillar/Detail/PillarDetail";
import { TermsAndAgreementModal } from "./components/TermsAndAgreementModal/TermsAndAgreementModal";
import reportWebVitals from "./reportWebVitals";

import "./i18n/i18n";
import "./index.css";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		errorElement: <Navigate to="/error" />,
		children: [
			{
				path: "*",
				element: <NotFoundErrorPage />,
			},
			{
				path: "/error",
				element: <GeneralErrorPage />,
			},
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/pillar/:pillarId",
				element: <Pillar />,
			},
			{
				path: "/pillar/:pillarId/:pillarSectionId",
				element: <PillarDetail />,
			},
			{
				path: "/introduction/:path",
				element: <IntroductionDetail />,
			},
			process.env.NODE_ENV !== "production"
				? {
						path: "/content-status-overview",
						element: <ContentOverview />,
				  }
				: {},
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<StrictMode>
		<HelmetProvider>
			<RouterProvider router={router} />
			<TermsAndAgreementModal />
		</HelmetProvider>
	</StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
