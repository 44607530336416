import { FC } from "react";

import Desktop from "../../../../assets/vectors/content/pillar-2/investment-trends-in-r-d-by-eu-member-states/investment-trends-in-r-d-by-eu-member-states.svg";

const InvestmentTrendsInRDByEuMemberStates: FC = () => {
	return (
		<div className="flex flex-col items-start justify-center">
			<img className="block w-full md:max-w-2xl " src={Desktop} role="presentation" />
		</div>
	);
};

export { InvestmentTrendsInRDByEuMemberStates };
