import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Legend } from "../../../Charts/Legend/Legend";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import Desktop from "../../../../assets/vectors/content/pillar-1/weee-generated-by-inhabitant/WEEE generated by inhabitants-desktop.svg";
import Mobile from "../../../../assets/vectors/content/pillar-1/weee-generated-by-inhabitant/WEEE generated by inhabitants-mobile.svg";

import { WEEE_GENERATED_BY_INHABITANTS_LEGEND_ITEMS } from "./WeeeGeneratedByInhabitants.const";

const WeeeGeneratedByInhabitants: FC = () => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	return (
		<div className="flex flex-col gap-8">
			<div className="self-stretch md:self-center">
				<Legend
					items={WEEE_GENERATED_BY_INHABITANTS_LEGEND_ITEMS}
					direction={["xs", "sm", "md"].includes(chartSize || "") ? "vertical" : "horizontal"}
				/>
			</div>

			<div className="flex flex-col items-start justify-center pt-5 md:pt-0">
				<img
					className="hidden md:block w-full max-w-4xl"
					src={Desktop}
					alt={t("pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.visual.alt") as string}
					title={t("pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.visual.alt") as string}
				/>
				<img
					className="block md:hidden self-start max-w-2xl"
					src={Mobile}
					alt={t("pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.visual.alt") as string}
					title={t("pages.pillar.pillar1.weeeGeneratedByInhabitants.blocks.visual.alt") as string}
				/>
			</div>
		</div>
	);
};

export { WeeeGeneratedByInhabitants };
