import { FC } from "react";

import Desktop from "../../../../assets/vectors/content/pillar-1/routes-of-recycled-plastic/routes-of-recycled-plastic-desktop.svg";
import Mobile from "../../../../assets/vectors/content/pillar-1/routes-of-recycled-plastic/routes-of-recycled-plastic-mobile.svg";

const RoutesOfRecycledPlastic: FC = () => {
	return (
		<div className="flex flex-col items-center justify-center">
			<img className="hidden md:block w-full" src={Desktop} role="presentation" />
			<img className="block md:hidden max-w-lg" src={Mobile} role="presentation" />
		</div>
	);
};

export { RoutesOfRecycledPlastic };
