export const debounce = (callback: () => void, wait: number) => {
	let timeoutId = 0;

	return (...args: []) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => {
			// eslint-disable-next-line prefer-spread
			callback.apply(null, args);
		}, wait);
	};
};
