import { FC, useCallback, useEffect, useState, useMemo } from "react";
import * as d3 from "d3";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { ChartSize } from "../../../types/charts/size";
import { useTextMeasurer } from "../../../hooks/charts/useTextMeasurer";
import { UiBreakpoints } from "../../../config/ui/breakpoints";

import { getLabelPadding, sankeyLoader, setupLabels, setupLinkPaths, setupNodeBlocks } from "./SankeyDiagram.helper";
import { SankeyDiagramProps } from "./SankeyDiagram.type";

const SankeyDiagram: FC<SankeyDiagramProps> = ({
	nodes,
	links,
	width,
	height,
	align = "right",
	margin,
	maxValue,
	rangeBoundary,
	tickFormatter = (value) => value,
}) => {
	const [chartSize, setChartSize] = useState<ChartSize>();
	const [fontSize, setFontSize] = useState<number>();
	const [svgRef, setSvgRef] = useState<SVGSVGElement | undefined>();

	const { measureText } = useTextMeasurer(fontSize);

	const { width: windowWidth } = useWindowSize();

	const maxNodeHeight = useMemo(() => {
		if (!maxValue || !rangeBoundary) {
			return height;
		}
		const factor = rangeBoundary / maxValue;
		return height * factor;
	}, [height, maxValue, rangeBoundary]);

	const labelPadding = useMemo(() => {
		return getLabelPadding(measureText, nodes, links);
	}, [links, measureText, nodes]);

	const chartMargin = useMemo(() => {
		if (!margin || !align || !labelPadding) {
			return;
		}
		const padding = Object.values(labelPadding).reduce((acc, current) => acc + current * 1.2, 0);
		if (align === "right") {
			return { ...margin, left: margin.left + padding };
		} else {
			return { ...margin, right: margin.right + padding };
		}
	}, [margin, align, labelPadding]);

	const handleSvgRef = useCallback((node: SVGSVGElement) => {
		setSvgRef(node);
	}, []);

	useEffect(() => {
		if (windowWidth <= 500) {
			setChartSize("xs");
		} else if (windowWidth < UiBreakpoints.md) {
			setChartSize("sm");
		} else if (windowWidth < UiBreakpoints.lg) {
			setChartSize("md");
		} else if (windowWidth < 1440) {
			setChartSize("lg");
		} else {
			setChartSize("xl");
		}
	}, [windowWidth]);

	useEffect(() => {
		switch (chartSize) {
			case "xl":
				setFontSize(25);
				break;
			case "lg":
				setFontSize(32);
				break;
			case "md":
				setFontSize(25);
				break;
			case "sm":
				setFontSize(30);
				break;
			case "xs":
				setFontSize(32);
				break;
		}
	}, [chartSize]);

	useEffect(() => {
		if (!svgRef || !links || !align || !chartMargin || !fontSize || !labelPadding) {
			return;
		}

		const svgWidth = width - chartMargin.left - chartMargin.right;
		const svgHeight = maxNodeHeight - chartMargin.top - chartMargin.bottom;

		const svgSelection = d3.select(svgRef);
		svgSelection.select("#labels").selectAll("g").remove();
		svgSelection.select("#content").selectAll("path").remove();
		svgSelection.select("#content").selectAll(".node").remove();

		svgSelection.select("#margin").attr("transform", "translate(" + chartMargin.left + "," + chartMargin.top + ")");

		const layoutLoader = sankeyLoader.nodes(nodes).links(links).size([svgWidth, svgHeight]);
		const graph = layoutLoader({ links: links, nodes: nodes });

		setupLabels(svgRef, graph, align, fontSize, labelPadding, tickFormatter);
		setupLinkPaths(svgRef, graph);
		setupNodeBlocks(svgRef, graph);
	}, [links, svgRef, nodes, width, maxNodeHeight, align, chartMargin, fontSize, labelPadding, tickFormatter]);

	return (
		<svg viewBox={`0 0 ${width} ${maxNodeHeight}`} className="w-full h-auto" ref={handleSvgRef}>
			<g id="margin">
				<g id="labels" transform="translate(0, -10)"></g>
				<g id="content"></g>
			</g>
		</svg>
	);
};

export { SankeyDiagram };
