export const UiColors = {
	// Keep in sync with `tailwind.config.json`
	primary: "#3cb4e5",
	"primary-hover": "#1c9dd2",
	secondary: "#0047ba",
	"secondary-hover": "#003487",

	romance: "#fdfdfd",

	// Should not be synced with tailwind (already available):
	black: "#000000",
	white: "#ffffff",

	// Chart colors
	charts: {
		grid: "#e2e2e2",
		primary: "#25498d",
		secondary: "#3cb4e5",
		tertiary: "#00919d",
		quaternary: "#c6c5c1",
	},
};
