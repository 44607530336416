import { UiColors } from "../../../../config/ui/colors";

export const WEEE_GENERATED_IN_EUROPE_CHART_DATA_KEYS = [
	{
		name: "largeHomeAppliances",
		color: UiColors.charts.primary,
		translation: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.chart.dataKeys.largeHomeAppliances",
	},
	{
		name: "smallHomeAppliances",
		color: UiColors.charts.secondary,
		translation: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.chart.dataKeys.smallHomeAppliances",
	},
	{
		name: "coolingAndFreezing",
		color: UiColors.charts.tertiary,
		translation: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.chart.dataKeys.coolingAndFreezing",
	},
	{
		name: "others",
		color: UiColors.charts.quaternary,
		translation: "pages.pillar.pillar1.weeeGeneratedInEurope.blocks.chart.dataKeys.others",
	},
];
