import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChartSize } from "../../../../types/charts/size";
import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { StackedHorizontalBarChart } from "../../../Charts/StackedHorizontalBarChart/StackedHorizontalBarChart";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { WEEE_GENERATED_BY_EU_MEMBER_STATES_CHART_DATA_KEYS } from "./WeeeGeneratedByEuMemberStates.const";
import { WeeeGeneratedByEuMemberStatesProps } from "./WeeeGeneratedByEuMemberStates.type";

const WeeeGeneratedByEuMemberStates: FC<WeeeGeneratedByEuMemberStatesProps> = ({ data = [] }) => {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const [chartSize, setChartSize] = useState<ChartSize>("md");
	const [legendDirection, setLegendDirection] = useState<LegendProps["direction"]>("horizontal");

	const [barChartTickCount, setBarChartTickCount] = useState<number>(11);
	const [axisFontSize, setAxisFontSize] = useState(14);

	useEffect(() => {
		if (width < 270) {
			setChartSize("xs");
		} else if (width < 450) {
			setChartSize("sm");
		} else if (width < 600) {
			setChartSize("md");
		} else if (width < 800) {
			setChartSize("lg");
		} else {
			setChartSize("xl");
		}
	}, [width]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setAxisFontSize(11);
				setBarChartTickCount(3);
				setLegendDirection("vertical");
				break;

			case "sm":
				setAxisFontSize(11);
				setBarChartTickCount(5);
				setLegendDirection("vertical");
				break;

			case "md":
			case "lg":
				setAxisFontSize(14);
				setBarChartTickCount(5);
				setLegendDirection("horizontal");
				break;

			default:
				setAxisFontSize(14);
				setBarChartTickCount(6);
				setLegendDirection("horizontal");
				break;
		}
	}, [chartSize]);

	const sortedData = useMemo(() => {
		return data.sort((a, b) => b.collected - a.collected);
	}, [data]);

	const legendData = useMemo(() => {
		return WEEE_GENERATED_BY_EU_MEMBER_STATES_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	const barLabelFormatter = useCallback((value: number) => {
		// Hide labels with value of 0
		if (value === 0) {
			return "";
		}

		return `${value}%`;
	}, []);

	return (
		<div className="flex flex-col gap-2">
			<div className="flex justify-center">
				<div className="w-full sm:w-auto">
					<Legend items={legendData} direction={legendDirection} />
				</div>
			</div>
			<StackedHorizontalBarChart
				data={sortedData}
				dataKeys={WEEE_GENERATED_BY_EU_MEMBER_STATES_CHART_DATA_KEYS}
				YAxisProps={{
					dataKey: "name",
					padding: { top: 15, bottom: 0 },
					fontSize: axisFontSize,
				}}
				XAxisProps={{
					orientation: "top",
					unit: "%",
					tickCount: barChartTickCount,
					fontSize: axisFontSize,
				}}
				barLabelFormatter={barLabelFormatter}
				barLabelPosition="insideRight"
			/>
		</div>
	);
};

export { WeeeGeneratedByEuMemberStates };
